import React, { useState, useEffect } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { usePermissions } from '@askporter/auth-provider';
import {
  SlaCurrentStatusReadOnly,
  SearchTaskSort,
  TaskStatusType,
  SearchTaskStats,
  SearchTaskFilter,
  ExtendedMetadataAttributeFilterSummaryExternal,
  SlaEscalationStatus,
} from '@askporter/client-grieg-lyric';
import { ListSideBar, SearchFilters, SearchSort } from '@askporter/component-library';
import {
  countAppliedExtendedMetadataFilters,
  ManagerRoles,
  TaskFilterPermissions,
  useDebounce,
} from '@askporter/utils';
import doAssetSearch from '../../api/doAssetSearch';
import doManagerSearch from '../../api/doManagerSearch';
import doTeamSearch from '../../api/doTeamSearch';
import doUserSearch from '../../api/doUserSearch';
import fetchTaskTypes from '../../api/fetchTaskTypes';
import { FilterSections, QuickFilters } from '../../components';
import {
  Actions,
  TransformedTaskFilterValues,
  TaskFilterErrors,
  ConfigBasedTaskFilterKeys,
  TaskTypeGroupProps,
} from '../../types';
import { useTaskFilterHandlers, configBasedTaskFilters } from '../../utils';

export interface TaskListSideBarProps {
  filters: SearchTaskFilter;
  taskFilterValues: Record<ConfigBasedTaskFilterKeys, TransformedTaskFilterValues>;
  errors: TaskFilterErrors;
  isLoading: boolean;
  isSmallDevice: boolean;
  t: (key: string, options?: Record<string, string | number>) => string;
  actions: Actions;
  filterPermissions: TaskFilterPermissions;
  sort: SearchTaskSort;
  sideBarDrawerMode?: {
    show: boolean;
    onClose: () => void;
  };
  stats: SearchTaskStats;
  extendedMetadataFilters?: ExtendedMetadataAttributeFilterSummaryExternal[];
  TaskTypeGroup: React.FC<React.PropsWithChildren<TaskTypeGroupProps>>;
}

/**
 * Renders the TaskListSideBar component display
 * @param filters - filter state
 * @param taskFilterValues - the retrieved values for config based task filters
 * @param errors - object containing the error state of values retrieved from API calls
 * @param isLoading - whether the page is loading
 * @param isSmallDevice - whether it's a small viewport or not
 * @param t - translation function
 * @param actions - reducer action functions
 * @param filterPermissions - object describing the filter visibility
 * @param sort - sort string
 * @param sideBarDrawerMode - optional, defines the side drawer behavior and styling
 * @param stats - the counts for each filter type
 * @param TaskTypeGroup
 */
export const TaskListSideBar: React.FC<React.PropsWithChildren<TaskListSideBarProps>> = ({
  filters,
  isLoading,
  isSmallDevice,
  t,
  actions,
  filterPermissions,
  sort,
  taskFilterValues,
  errors,
  sideBarDrawerMode = undefined,
  stats,
  extendedMetadataFilters,
  TaskTypeGroup,
}: TaskListSideBarProps) => {
  const configBasedFilterKeys: Array<ConfigBasedTaskFilterKeys> = isLoading ? [] : configBasedTaskFilters;
  const [configBasedFilterSearch, setConfigBasedFilterSearch] = useState<Record<string, string>>({});

  const configBasedFiltersDefaulted =
    !isLoading && Object.keys(configBasedFilterSearch).length === configBasedFilterKeys.length;
  const handlers = useTaskFilterHandlers(actions, setConfigBasedFilterSearch, filters);

  // if user's do not have permission to the team or assignee filter then ensure there are no redundant network calls
  const canSearchForAssignee = usePermissions(filterPermissions.assignedUser);
  const canSearchForTeam = usePermissions(filterPermissions.assignedTeam);

  const count =
    // date filters
    +Boolean(filters.created?.after || filters.created?.before) +
    +Boolean(filters.resolutionDueDate?.after || filters.resolutionDueDate?.before) +
    +Boolean(filters.resolutionDueDateTime?.after || filters.resolutionDueDateTime?.before) +
    +Boolean(filters.responseDueDateTime?.after || filters.responseDueDateTime?.before) +
    // array filters
    (filters?.assignedTeam?.length || 0) +
    (filters?.assignedUser?.length || 0) +
    (filters?.linkedAsset?.length || 0) +
    (filters?.responseSlaStatus?.length || 0) +
    (filters?.resolutionSlaStatus?.length || 0) +
    (filters?.statusType?.length || 0) +
    // config based filters
    (filters?.priority?.length || 0) +
    (filters?.status?.length || 0) + // TODO, when counts are displayed we will need a more complex count
    (filters?.taskType?.length || 0) +
    // extended metadata filters
    countAppliedExtendedMetadataFilters(filters);

  const taskTypesQuery = useQuery('task-types', () => fetchTaskTypes());

  // linked user search
  const [linkedUserSearchValue, setLinkedUserSearchValue] = useState('');
  const debouncedLinkedUserSearchValue = useDebounce<string>(linkedUserSearchValue, 700);
  const postLinkedUserSearchKey = {
    path: 'users/search',
    payload: { freeText: debouncedLinkedUserSearchValue },
  };
  const {
    data: linkedUserSearchResults,
    isLoading: isLinkedUserSearchResultsLoading,
    isFetchingNextPage: linkedUserSearchResultsMoreLoading,
    hasNextPage: linkedUserSearchResultsHasMore,
    fetchNextPage: linkedUserSearchResultsFetchMore,
  } = useInfiniteQuery(
    [postLinkedUserSearchKey],
    async ({ pageParam = 1 }) => {
      const response = await doUserSearch({
        payload: { freeText: debouncedLinkedUserSearchValue, page: pageParam, pageSize: 10 },
      });
      return { response, nextPage: pageParam + 1 };
    },
    {
      getNextPageParam: (data) => {
        return data?.response?.summary?.totalPages >= data?.nextPage ? data?.nextPage : undefined;
      },
      enabled: Boolean(debouncedLinkedUserSearchValue === '' || debouncedLinkedUserSearchValue?.length > 2),
    },
  );

  // assignee filter search
  const [assigneeSearchValue, setAssigneeSearchValue] = useState('');
  const debouncedAssignee = useDebounce<string>(assigneeSearchValue, 700);
  const postUserSearchKey = {
    path: 'users/search',
    payload: {
      freeText: debouncedAssignee,
      filter: {
        role: ManagerRoles,
      },
    },
  };
  const {
    data: assigneeSearchResults,
    isLoading: assigneeSearchResultsLoading,
    isError: userIsError,
    isFetchingNextPage: assigneeSearchMoreResultsLoading,
    hasNextPage: assigneeSearchHasMore,
    fetchNextPage: assigneeSearchFetchMore,
  } = useInfiniteQuery(
    [postUserSearchKey],
    async ({ pageParam = 1 }) => {
      const response = await doManagerSearch({ freeText: debouncedAssignee, page: pageParam, pageSize: 10 });
      return { response, nextPage: pageParam + 1 };
    },
    {
      enabled: canSearchForAssignee && Boolean(debouncedAssignee === '' || debouncedAssignee?.length > 2),
      getNextPageParam: (data) => {
        return data?.response?.summary?.totalPages >= data?.nextPage ? data?.nextPage : undefined;
      },
    },
  );

  // asset filter search
  const [assetSearchValue, setAssetSearchValue] = useState('');
  const debouncedAsset = useDebounce<string>(assetSearchValue, 700);
  const postAssetSearchKey = {
    path: 'assets/search',
    payload: { freeText: debouncedAsset },
  };
  const {
    data: assetSearchResult,
    isLoading: assetSearchResultLoading,
    isError: assetIsError,
    isFetchingNextPage: assetSearchMoreResultsLoading,
    hasNextPage: assetSearchHasMore,
    fetchNextPage: assetSearchFetchMore,
  } = useInfiniteQuery(
    [postAssetSearchKey],
    async ({ pageParam = 1 }) => {
      const response = await doAssetSearch({ payload: { freeText: debouncedAsset, page: pageParam, pageSize: 10 } });
      return { response, nextPage: pageParam + 1 };
    },
    {
      getNextPageParam: (data) => {
        return data?.response?.summary?.totalPages >= data?.nextPage ? data?.nextPage : undefined;
      },
      enabled: Boolean(debouncedAsset === '' || debouncedAsset?.length > 2),
    },
  );

  // team filter search
  const [teamSearchValue, setTeamSearchValue] = useState('');
  const debouncedTeam = useDebounce<string>(teamSearchValue, 700);
  const postTeamSearchKey = { path: 'teams/search', payload: { freeText: debouncedTeam } };
  const {
    data: teamSearchResults,
    isLoading: teamSearchResultsLoading,
    isError: teamIsError,
    isFetchingNextPage: teamSearchMoreResultsLoading,
    hasNextPage: teamSearchHasMore,
    fetchNextPage: teamSearchFetchMore,
  } = useInfiniteQuery(
    [postTeamSearchKey],
    async ({ pageParam = 1 }) => {
      const response = await doTeamSearch({ payload: { freeText: debouncedTeam, page: pageParam, pageSize: 10 } });
      return { response, nextPage: pageParam + 1 };
    },
    {
      enabled: canSearchForTeam && Boolean(debouncedTeam === '' || debouncedTeam?.length > 2),
      getNextPageParam: (data) => {
        return data?.response?.summary?.totalPages >= data?.nextPage ? data?.nextPage : undefined;
      },
    },
  );

  // sla statuses
  const dueSlaStatuses = Object.values(SlaCurrentStatusReadOnly).map((value: SlaCurrentStatusReadOnly) => ({
    value,
    label:
      value !== SlaCurrentStatusReadOnly.Na && value !== 'PAUSED'
        ? t(`task_filter:section:sla:due_status:${value.toLowerCase()}`)
        : '',
    count: stats?.dueSlaStatuses?.find((status) => status?.statusType === value)?.count || 0,
  }));
  const responseSlaStatuses = Object.values(SlaCurrentStatusReadOnly).map((value: SlaCurrentStatusReadOnly) => ({
    value,
    label:
      value !== SlaCurrentStatusReadOnly.Na ? t(`task_filter:section:sla:respond_status:${value.toLowerCase()}`) : '',
    count: stats?.responseSlaStatuses?.find((status) => status?.statusType === value)?.count || 0,
  }));
  const resolveSlaStatuses = Object.values(SlaCurrentStatusReadOnly).map((value: SlaCurrentStatusReadOnly) => ({
    value,
    label:
      value !== SlaCurrentStatusReadOnly.Na ? t(`task_filter:section:sla:resolve_status:${value.toLowerCase()}`) : '',
    count: stats?.resolutionSlaStatuses?.find((status) => status?.statusType === value)?.count || 0,
  }));

  // Escalation
  const escalationStatuses = Object.values(SlaEscalationStatus).map((value: SlaEscalationStatus) => ({
    value,
    label: value !== SlaEscalationStatus.None ? t(`task_filter:section:escalation_status:${value.toLowerCase()}`) : '',
    count: stats?.escalationStatuses?.find((status) => status?.statusType === value)?.count || 0,
  }));

  // status types
  const statusTypes = Object.values(TaskStatusType)
    .map((value: TaskStatusType) => ({
      value,
      label: t(`ns.task_filter:section:status_type:${value.toLowerCase()}`, { defaultValue: value }),
      count: stats?.statusTypes?.find((statusTypeStat) => statusTypeStat?.statusType === value)?.count || 0,
    }))
    .filter((statusType) => statusType.count > 0);

  useEffect(() => {
    // once filters are loaded, create an object containing the initial filter search strings
    if (!isLoading && !configBasedFiltersDefaulted) {
      setConfigBasedFilterSearch(configBasedFilterKeys.reduce((obj, key) => Object.assign(obj, { [key]: '' }), {}));
    }
  }, [configBasedFilterKeys]);

  return (
    <ListSideBar
      isSmallDevice={isSmallDevice}
      t={t}
      setIsSearchEnabled={actions.setIsSearchEnabled}
      initialData={{ filters, sort }}
      restoreInitialData={(data) => {
        const { filters = {}, sort = SearchTaskSort.Recommended } = data || {};

        actions.setFilters(filters);
        actions.setSort(sort);
      }}
      drawerMode={sideBarDrawerMode}
    >
      <SearchFilters
        t={t}
        filterCount={count}
        onClearAllClick={actions.clearAllFilters}
        filterDescriptionKey="task_filter:description"
      >
        <SearchSort
          t={t}
          items={[
            { value: SearchTaskSort.Recommended, label: `${t('ns.task_filter:sort:recommended')}` },
            { value: SearchTaskSort.CreatedAsc, label: t('ns.task_filter:sort:created_asc') },
            { value: SearchTaskSort.CreatedDesc, label: t('ns.task_filter:sort:created_desc') },
          ]}
          onChange={(value) => actions.setSort(value as SearchTaskSort)}
          initialValue={sort}
        />
        {/* Quick filters */}
        {!isLoading ? (
          <QuickFilters t={t} filters={filters} actions={actions} filterPermissions={filterPermissions} />
        ) : null}

        <FilterSections
          TaskTypeGroup={TaskTypeGroup}
          areFilterCountsLoading={!stats}
          filters={filters}
          t={t}
          actions={actions}
          dateTimeOnChange={handlers.handleDateTimeOnChange}
          filterPermissions={filterPermissions}
          linkedUser={{
            searchValue: linkedUserSearchValue,
            onChange: setLinkedUserSearchValue,
            searchResults: linkedUserSearchResults?.pages.reduce(
              (acc, item) => acc.concat([...item.response.results]),
              [],
            ),
            isLoading: isLinkedUserSearchResultsLoading,
            hasMore: linkedUserSearchResultsHasMore,
            fetchMore: linkedUserSearchResultsFetchMore,
            moreResultsLoading: linkedUserSearchResultsMoreLoading,
            totalResults: linkedUserSearchResults?.pages[0]?.response.summary.totalResults,
          }}
          assignedUser={{
            searchValue: assigneeSearchValue,
            onChange: setAssigneeSearchValue,
            searchResults: assigneeSearchResults?.pages.reduce(
              (acc, item) => acc.concat([...item.response.results]),
              [],
            ),
            isLoading: assigneeSearchResultsLoading,
            hasMore: assigneeSearchHasMore,
            fetchMore: assigneeSearchFetchMore,
            moreResultsLoading: assigneeSearchMoreResultsLoading,
            totalResults: assigneeSearchResults?.pages[0]?.response.summary.totalResults,
          }}
          assignedTeam={{
            searchValue: teamSearchValue,
            onChange: setTeamSearchValue,
            searchResults: teamSearchResults?.pages.reduce((acc, item) => acc.concat([...item.response.results]), []),
            isLoading: teamSearchResultsLoading,
            hasMore: teamSearchHasMore,
            fetchMore: teamSearchFetchMore,
            moreResultsLoading: teamSearchMoreResultsLoading,
            totalResults: teamSearchResults?.pages[0]?.response.summary.totalResults,
          }}
          linkedAsset={{
            searchValue: assetSearchValue,
            onChange: setAssetSearchValue,
            searchResults: assetSearchResult?.pages.reduce((acc, item) => acc.concat([...item.response.results]), []),
            isLoading: assetSearchResultLoading,
            hasMore: assetSearchHasMore,
            fetchMore: assetSearchFetchMore,
            moreResultsLoading: assetSearchMoreResultsLoading,
            totalResults: assetSearchResult?.pages[0]?.response.summary.totalResults,
          }}
          dueSlaStatuses={dueSlaStatuses}
          responseSlaStatuses={responseSlaStatuses}
          resolveSlaStatuses={resolveSlaStatuses}
          escalationStatuses={escalationStatuses}
          statusTypes={statusTypes}
          taskTypesQuery={taskTypesQuery}
          stats={stats}
          errors={{ ...errors, userIsError, teamIsError, assetIsError }}
          configBasedTaskFilterValues={taskFilterValues}
          configBasedTaskFilterValuesLoading={isLoading}
          configBasedFilterSearch={configBasedFilterSearch}
          configBasedFilterSearchOnChange={handlers.handleSearchOnChange}
          isSmallDevice={isSmallDevice}
          extendedMetadataFilters={extendedMetadataFilters}
        />
      </SearchFilters>
    </ListSideBar>
  );
};
