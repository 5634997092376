import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthProvider, SessionState } from '@askporter/auth-provider';
import { Loading } from '@askporter/component-library';

interface RequireAuthProps {
  /**
   * The children to render if the user is authenticated.
   */
  children: React.JSX.Element;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { sessionState, feedbackMessage } = useAuthProvider();
  const location = useLocation();

  switch (sessionState) {
    case SessionState.pendingRefresh:
    case SessionState.valid:
      return children;
    case SessionState.invalid:
    case SessionState.illegal: {
      return (
        <Navigate
          to="/sign-in"
          state={{
            to: feedbackMessage === 'signed out' ? undefined : location.pathname,
          }}
        />
      );
    }
    case SessionState.pendingRehydrate:
    default:
      return <Loading />;
  }
};
