import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import { StepIcon, StepIconProps } from '@mui/material';

export type ActivityStepIconProps = StepIconProps;

export const ActivityStepIcon = (props: ActivityStepIconProps) => {
  const { completed, active } = props;

  return (
    <StepIcon
      {...props}
      icon={
        !completed ? (
          <CircleIcon color="inherit" data-testid="task-in-progress-icon" />
        ) : active ? (
          <CheckCircleIcon color="inherit" data-testid="active-task-completed-icon" />
        ) : (
          <CheckCircleOutlineIcon color="inherit" data-testid="task-completed-icon" />
        )
      }
    />
  );
};
