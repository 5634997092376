import React from 'react';
import { Box, FormGroup, Theme, useMediaQuery } from '@mui/material';
import { PeopleSelector } from '@askporter/autocomplete';
import { useTranslation } from '@askporter/config-provider';
import { TaskTypeGroupProps } from '@askporter/utils';
import ThreeStateCheckbox from '../ThreeStateCheckbox';

/**
 * Renders a TaskTypeGroup component which handles the visual behaviour, grouping and indenting of task types
 * @param checkedUids - list of UIDs that are checked
 * @param groupOnChange - function that handles the scenario where the top level checkbox for a group changes
 * @param checkboxOnChange - function handling individual checkbox changes
 * @param assigneeState - map of task type UIDs to default assignee
 * @param assigneeOnChange - handle change of default assignee
 * @param rightAdornment - defaults to displaying the assignee box, but can render a function returning a JSX element in place
 * @param taskTypeGroup - the TaskTypeGroup object
 */
const TaskTypeGroup: React.FC<React.PropsWithChildren<TaskTypeGroupProps>> = ({
  checkedUids,
  groupCheckboxOnChange,
  checkboxOnChange,
  assigneeState,
  assigneeOnChange,
  groupAssigneeState,
  groupAssigneeOnChange,
  rightAdornment = 'ASSIGNEE',
  taskTypeGroup,
  disabled,
}: TaskTypeGroupProps) => {
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // additional styling is required to ensure checkboxes are styled correctly when used on filter lists
  const nonDefaultAdornmentStyles =
    rightAdornment !== 'ASSIGNEE'
      ? { threeStateCheckbox: { sx: { mx: 1.75 }, formControlLabelSx: { ml: 0 } }, wrapper: { display: 'flex' } }
      : {};

  let groupCheckboxState: boolean | 'indeterminate' = false;
  if (taskTypeGroup.taskTypes.every((type) => checkedUids.includes(type.uid))) groupCheckboxState = true;
  else if (taskTypeGroup.taskTypes.some((type) => checkedUids.includes(type.uid))) groupCheckboxState = 'indeterminate';

  const toggleGroup = () => {
    const nonDefaultUids = taskTypeGroup.taskTypes.filter((type) => !type.default).map((type) => type.uid);

    if (groupCheckboxState === 'indeterminate' || groupCheckboxState === false)
      groupCheckboxOnChange(nonDefaultUids, true);
    else if (groupCheckboxState === true) groupCheckboxOnChange(nonDefaultUids, false);
  };

  return (
    <FormGroup sx={{ mb: 4 }} data-testid={`${taskTypeGroup.displayName}-form-group`}>
      {/* When the isGroup flag is false then the group only contains one item so simply render that item */}
      {!taskTypeGroup.isGroup ? (
        <Box
          sx={{ display: isSmallDevice ? 'block' : 'flex', alignItems: 'center', ...nonDefaultAdornmentStyles.wrapper }}
        >
          <Box sx={{ flexBasis: '100%' }}>
            <ThreeStateCheckbox
              key={taskTypeGroup.taskTypes[0].uid}
              label={taskTypeGroup.taskTypes[0].displayName}
              state={checkedUids.includes(taskTypeGroup.taskTypes[0].uid)}
              onChange={() => checkboxOnChange(taskTypeGroup.taskTypes[0].uid)}
              iconPath={taskTypeGroup.taskTypes[0]?.icon?.iconPath}
              disabled={taskTypeGroup.taskTypes[0]?.default} // default task type groups should be disabled
              data-testid={`${taskTypeGroup.displayName}-notgroup-checkbox`}
              {...nonDefaultAdornmentStyles.threeStateCheckbox}
            />
          </Box>

          {rightAdornment === 'ASSIGNEE' ? (
            <Box sx={{ flexBasis: '100%', ml: isSmallDevice ? 8 : 0 }}>
              <PeopleSelector
                disabled={disabled}
                t={t}
                idPrefix={`${taskTypeGroup.displayName}-${taskTypeGroup.taskTypes[0].displayName}`}
                label={t('ns.people_selector:default_assignee_label')}
                selectedUser={assigneeState?.[taskTypeGroup.taskTypes[0].uid]}
                onChange={(person) => {
                  assigneeOnChange(taskTypeGroup.taskTypes[0].uid, person, taskTypeGroup.uid);
                }}
              />
            </Box>
          ) : (
            rightAdornment(taskTypeGroup.taskTypes[0].uid, 'ITEM_AS_GROUP')
          )}
        </Box>
      ) : (
        // When isGroup is true create a group level checkbox and render each group item
        <>
          <Box
            sx={{
              display: isSmallDevice ? 'block' : 'flex',
              alignItems: 'center',
              ...nonDefaultAdornmentStyles.wrapper,
            }}
          >
            <Box sx={{ flexBasis: '100%' }}>
              <ThreeStateCheckbox
                label={taskTypeGroup.displayName}
                onChange={toggleGroup}
                state={groupCheckboxState}
                disabled={taskTypeGroup?.allDefault} // when all items within are default then this should be disabled
                data-testid={`${taskTypeGroup.displayName}-group-checkbox`}
                {...nonDefaultAdornmentStyles.threeStateCheckbox}
              />
            </Box>

            {rightAdornment === 'ASSIGNEE' ? (
              <Box sx={{ flexBasis: '100%', ml: isSmallDevice ? 8 : 0 }}>
                <PeopleSelector
                  disabled={disabled}
                  t={t}
                  idPrefix={`${taskTypeGroup.displayName}`}
                  label={t('ns.people_selector:assign_group_label')}
                  selectedUser={groupAssigneeState?.[taskTypeGroup.uid]}
                  onChange={(person) => {
                    groupAssigneeOnChange(taskTypeGroup.uid, person);
                  }}
                />
              </Box>
            ) : (
              rightAdornment(taskTypeGroup.uid, 'GROUP')
            )}
          </Box>
          {taskTypeGroup.taskTypes.map((type) => {
            return (
              <Box
                key={type.uid}
                sx={{
                  display: isSmallDevice ? 'block' : 'flex',
                  alignItems: 'center',
                  ...nonDefaultAdornmentStyles.wrapper,
                }}
              >
                <Box sx={{ flexBasis: '100%' }}>
                  <ThreeStateCheckbox
                    label={type.displayName}
                    state={checkedUids.includes(type.uid)}
                    onChange={() => checkboxOnChange(type.uid)}
                    iconPath={type?.icon?.iconPath}
                    disabled={type?.default} // default task type groups should be disabled
                    formControlLabelSx={{ ml: 6 }}
                    data-testid={`${type.displayName}-group-item-checkbox`}
                  />
                </Box>

                {rightAdornment === 'ASSIGNEE' ? (
                  <Box sx={{ flexBasis: '100%', minHeight: '80px', ml: isSmallDevice ? 16 : 0 }}>
                    <PeopleSelector
                      disabled={disabled}
                      t={t}
                      idPrefix={`${taskTypeGroup.displayName}-${type.displayName}`}
                      label={t('ns.people_selector:default_assignee_label')}
                      selectedUser={assigneeState?.[type.uid]}
                      onChange={(person) => {
                        assigneeOnChange(type.uid, person, taskTypeGroup.uid);
                      }}
                    />
                  </Box>
                ) : (
                  rightAdornment(type.uid, 'GROUP_ITEM')
                )}
              </Box>
            );
          })}
        </>
      )}
    </FormGroup>
  );
};

export default TaskTypeGroup;
