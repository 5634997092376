import React from 'react';
import { SxProps, Theme } from '@mui/material';
import {
  AssetSearchResultExternal,
  AssetSummaryAncestralExternal,
  SearchAssetResultExternal,
} from '@askporter/client-grieg-lyric';
import { IconProps } from '@askporter/component-library';
import { useSearchAutocomplete } from '../../hooks/useSearchAutocomplete';
import SearchAutocomplete, { SearchAutocompleteProps } from '../SearchAutocomplete';
import AssetOptionAvatar from './components/AssetOptionAvatar';
import AssetRenderOption from './components/AssetRenderOption';

export type AssetSummary = (AssetSearchResultExternal | AssetSummaryAncestralExternal) & { assetTypeUid?: string };

type PermittedSearchAutocompleteProps = Pick<
  SearchAutocompleteProps<AssetSummary>,
  'sx' | 't' | 'label' | 'disabled' | 'error'
>;

type AssetAutocompleteProps = PermittedSearchAutocompleteProps & {
  onChange: (asset: AssetSummary) => void;
  value: AssetSummary | null;
  readOnly?: boolean;
  readOnlyText?: string;
  textFieldSx?: SxProps<Theme>;
};

/** Renders an AssetAutocomplete component */
const AssetAutocomplete: React.FC<React.PropsWithChildren<AssetAutocompleteProps>> = (
  props: AssetAutocompleteProps,
) => {
  const placeholderIconFolder = 'system/02-assets' as IconProps['folder'];
  const autocompleteProps = useSearchAutocomplete<AssetSummary, SearchAssetResultExternal>({
    path: 'assets/search',
  });

  return (
    <SearchAutocomplete<AssetSummary>
      {...props}
      {...autocompleteProps}
      idPrefix={'asset'}
      getOptionLabel={(asset: AssetSummary) => asset?.title || asset?.assetRef || ''}
      renderOption={(liProps: React.HTMLAttributes<HTMLLIElement>, asset: AssetSearchResultExternal) => (
        <AssetRenderOption key={`${liProps.id}-${asset?.uid}`} liProps={liProps} asset={asset} />
      )}
      startAdornment={
        !!props?.value && !!autocompleteProps.searchValue && !autocompleteProps.request ? (
          <AssetOptionAvatar asset={props?.value} />
        ) : (
          <AssetOptionAvatar
            asset={{
              assetType: {
                uid: '',
                urn: '',
                displayName: 'Placeholder',
                icon: { iconPath: `/media/icons/${placeholderIconFolder}/` },
              },
            }}
          />
        )
      }
    />
  );
};

export default AssetAutocomplete;
