import React from 'react';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Route, useLocation, Routes, Navigate } from 'react-router-dom';
import { useAuthProvider } from '@askporter/auth-provider';
import { RoleSlug } from '@askporter/client-grieg-lyric';
import { Loading } from '@askporter/component-library';
import { useConfig } from '@askporter/config-provider';
import { ExportProvider } from '@askporter/exports';
import { ProfileProvider, useProfile } from '@askporter/profile-provider';
import { GeneralErrorBoundaryWithRumIfEnabled } from '@askporter/rum';
import { checkPermissions, ManagerRoles, CU_FeatureFlags } from '@askporter/utils';
import { WebchatPageProps } from '@askporter/webchat-pages';
import useSetLanguage from '../../hooks/useSetLanguage';
import Rum from '../../providers/Rum';
import { lazyWithRetry, config, useAttributes, useForgotPassword, useVerifyAttribute } from '../../utils';
import { API } from '../../utils/api';
import CoreNavigation from '../CoreNavigation';
import TaskTypeGroup from '../TaskTypeGroup';

const Home = lazyWithRetry<WebchatPageProps>(() => import('@askporter/webchat-pages/src/Home'));
const MobileNumber = lazyWithRetry(() => import('../../pages/App/MobileNumber'));
const Tasks = lazyWithRetry(() => import('@askporter/tasks'));
const Account = lazyWithRetry(() => import('@askporter/account'));
const Assets = lazyWithRetry(() => import('@askporter/assets'));
const People = lazyWithRetry(() => import('@askporter/people'));
const PerformanceStats = lazyWithRetry(() => import('@askporter/perf-stats'));
const AdminConfig = lazyWithRetry(() => import('@askporter/admin-config'));
const Exports = lazyWithRetry(() => import('@askporter/exports'));

export const PrivateRouter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { userProfile, authToken } = useAuthProvider();
  const { featureFlags } = useConfig();
  const { pathname } = useLocation();

  const isServiceUser = userProfile
    ? checkPermissions(userProfile['custom:ap_role'], [RoleSlug.Svcusr, RoleSlug.SvcusrSpr])
    : undefined;

  const isAdmin = userProfile
    ? checkPermissions(userProfile['custom:ap_role'], [RoleSlug.PlatAdmin, RoleSlug.ClientAdmin])
    : undefined;

  const isManagerOrSuperServiceUser = userProfile
    ? checkPermissions(userProfile['custom:ap_role'], [...ManagerRoles, RoleSlug.SvcusrSpr])
    : undefined;

  const { isLanguageConsistent, setLanguage } = useSetLanguage();
  const profileProviderProps = {
    authToken,
    isLanguageConsistent,
    setLanguage,
    API,
  };

  const webchatConfig = {
    DEV_ASSISTANT_SECRET: config.DEV_ASSISTANT_SECRET,
  };

  const ipGeolocationApiKey = config.IP_GEOLOCATION_API_KEY;
  const shouldShowRoutes = userProfile && typeof isServiceUser !== 'undefined' && typeof isAdmin !== 'undefined';
  if (!shouldShowRoutes) {
    return <><Loading /></>
  }

  return (
    <>
      <ProfileProvider {...profileProviderProps}>
        <Rum>
          <GeneralErrorBoundaryWithRumIfEnabled navigation={<CoreNavigation />}>
            <ExportProvider pathname={pathname}>
              <Routes>
                {isServiceUser && (
                  <Route index element={
                    <Home CoreNavigation={CoreNavigation} webchatConfig={webchatConfig} />
                  } />)}

                {!isServiceUser && (
                  <Route index element={<Navigate to="tasks" />} />
                )}
                <Route path="tasks/*" element={<Tasks
                  CoreNavigation={CoreNavigation}
                  TaskTypeGroup={TaskTypeGroup}
                  webchatConfig={webchatConfig}
                />} />
                <Route path="assets/*" element={
                  <Assets
                    CoreNavigation={CoreNavigation}
                    TaskTypeGroup={TaskTypeGroup}
                    ipGeolocationApiKey={ipGeolocationApiKey}
                  />
                } />
                <Route path="people/*" element={
                  <People
                    CoreNavigation={CoreNavigation}
                    TaskTypeGroup={TaskTypeGroup}
                    useForgotPassword={useForgotPassword}
                  />
                } />
                <Route path="account/*" element={
                  <Account
                    useForgotPassword={useForgotPassword}
                    useSetLanguage={useSetLanguage}
                    useProfile={useProfile}
                    CoreNavigation={CoreNavigation}
                    useAttributes={useAttributes}
                    useVerifyAttribute={useVerifyAttribute}
                    cognitoUserAttributes={(phoneNumber: string) => [
                      new CognitoUserAttribute({ Name: 'phone_number', Value: phoneNumber }),
                    ]}
                  />
                } />
                <Route path="chat/:conversationUid" element={
                  <Home CoreNavigation={CoreNavigation} webchatConfig={webchatConfig} />
                } />

                {featureFlags?.[CU_FeatureFlags.PERF_STATS] && isManagerOrSuperServiceUser && (
                  <Route path="performance-stats/*" element={
                    <PerformanceStats navigation={<CoreNavigation />} />
                  } />
                )}
                <Route path="verify-number/*" element={
                  <MobileNumber />
                } />
                {featureFlags?.[CU_FeatureFlags.ADMIN_CONFIG] && isAdmin && (
                  <Route path="admin-config/*" element={
                    <AdminConfig navigation={<CoreNavigation />} />
                  } />
                )}
                {featureFlags?.[CU_FeatureFlags.ENABLE_EXPORT] && (
                  <Route path="exports" element={
                    <Exports navigation={<CoreNavigation />} />
                  } />
                )}
                < Route path="*" element={
                  <Navigate
                    to="/404"
                    state={{ homeLink: '/app' }}
                    replace
                  />
                } />
              </Routes>
            </ExportProvider>
          </GeneralErrorBoundaryWithRumIfEnabled>
        </Rum>
      </ProfileProvider>
    </>
  )
};

export default PrivateRouter;
