import React from 'react';
import { UseQueryResult } from 'react-query';
import {
  SearchTaskFilter,
  SearchTaskStats,
  TaskTypeGroupListExternalTaskTypeGroups,
} from '@askporter/client-grieg-lyric';
import { FilterCount, FilterSection } from '@askporter/component-library';
import { toggleValueInList } from '@askporter/utils';
import { TaskTypeGroupProps, Actions } from '../../types';

interface TaskTypesFilterProps {
  filter: SearchTaskFilter['taskType'];
  setFilters: Actions['setFilters'];
  taskTypesQuery: UseQueryResult<TaskTypeGroupListExternalTaskTypeGroups[], unknown>;
  stats: SearchTaskStats;
  t: (key: string, options?: Record<string, string | number>) => string;
  TaskTypeGroup: React.FC<React.PropsWithChildren<TaskTypeGroupProps>>;
}

/**
 * Renders the Task Types filter section
 * @param filter - task
 * @param setFilters - set filter reducer action
 * @param taskTypesQuery - the query object for task types, providing access to data, loading & error
 * @param stats - search task stats
 * @param t - translations function
 */
export const TaskTypesFilter: React.FC<React.PropsWithChildren<TaskTypesFilterProps>> = ({
  filter,
  setFilters,
  taskTypesQuery,
  stats,
  t,
  TaskTypeGroup,
}: TaskTypesFilterProps) => {
  const statUids = stats?.taskTypes?.map((taskType) => taskType.uid) || [];
  const checkboxOnChange = (taskTypeUid: string) => setFilters({ taskType: toggleValueInList(taskTypeUid, filter) });

  const groupCheckboxOnChange = (taskTypeUids: Array<string>, value: boolean) => {
    let updatedSelectedTaskTypes = filter;

    taskTypeUids.forEach((uid) => {
      if ((value && !updatedSelectedTaskTypes.includes(uid)) || (!value && filter.includes(uid)))
        updatedSelectedTaskTypes = toggleValueInList(uid, updatedSelectedTaskTypes);
    });

    setFilters({ taskType: updatedSelectedTaskTypes });
  };

  return (
    <FilterSection
      key={`filter_section_taskType`}
      t={t}
      isLoading={taskTypesQuery?.isLoading}
      isError={taskTypesQuery?.isError}
      filterSectionTitle={t(`ns.task_filter:section:taskType`)}
      filterKey={'taskType'}
      searchLabel={t('ns.task_filter:search_label')}
      isExpandedOnMount={!!filter?.length}
      itemsCount={filter?.length}
    >
      {statUids?.length === 0
        ? null // returning null here ensues the no filters message is displayed if there are no filters
        : taskTypesQuery?.data?.map(({ taskTypes, ...otherTaskTypeProperties }, idx) => {
            // narrow the scope to just task types that have counts & only return if a group has at least one type
            const filteredTaskTypes = taskTypes.filter((type) => statUids?.includes(type.uid));
            if (filteredTaskTypes?.length >= 1)
              return (
                <TaskTypeGroup
                  key={`${otherTaskTypeProperties?.uid}_${idx}`}
                  checkedUids={filter}
                  checkboxOnChange={checkboxOnChange}
                  groupCheckboxOnChange={groupCheckboxOnChange}
                  taskTypeGroup={{ isGroup: true, taskTypes: filteredTaskTypes, ...otherTaskTypeProperties }}
                  rightAdornment={(uid, sender) =>
                    sender === 'GROUP_ITEM' && (
                      <FilterCount count={stats?.taskTypes?.find((stat) => stat.uid === uid)?.count || 0} />
                    )
                  }
                />
              );
          })}
    </FilterSection>
  );
};
