import React from 'react';
import { Box, useTheme } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon, IconButton, IconSize, Typography } from '../../../../';
import { NavbarLink, navButtonIsActive } from '../../utils';

interface FullNavBarLinkProps {
  fullNavButtons: Array<NavbarLink>;
  t: (key: string) => string;
}

/** Renders a the Full Nav Bar links */
const FullNavBarLink: React.FC<React.PropsWithChildren<FullNavBarLinkProps>> = ({
  fullNavButtons,
  t,
}: FullNavBarLinkProps) => {
  const theme = useTheme();
  const location = useLocation();
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignSelf="flex-end"
      height="100%"
      data-testid="desktop-full-nav-bar"
      sx={{ ml: theme.custom.spacing.lg }}
    >
      {fullNavButtons.map((button) => {
        const isActive = navButtonIsActive(button.href, location.pathname);
        return (
          <IconButton
            key={button.href}
            disableRipple
            sx={{
              px: 4,
              borderRadius: '0',
              '&:focus': {
                outline: `2px solid ${theme.palette.primary.main}`,
                outlineOffset: '-2px',
              },
              '&:hover': {
                backgroundColor: theme.palette.background.default,
              },
              borderBottom: isActive ? `3px solid ${theme.palette.primary.main}` : 'none',
              height: '100%',
            }}
            color="inherit"
            aria-label={t(`${button.translation}`)}
            component={NavLink}
            to={button.href}
            end={button.href === '/app'}
          >
            <Icon size={IconSize.MD} folder={button.iconFolder} />
            <Typography variant="body1" ml={2}>
              {t(`${button.translation}`)}
            </Typography>
          </IconButton>
        );
      })}
    </Box>
  );
};

export default FullNavBarLink;
