import React from 'react';
import { Datepicker, locale } from '@mobiscroll/react';
import type { MbscDatepickerOptions } from '@mobiscroll/react/dist/src/core/components/datepicker/datepicker';
import { CalendarToday } from '@mui/icons-material';
import { FilledInput, FilledInputProps, IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { formatRFC3339 } from 'date-fns';

export interface DateAndTimePickerProps extends MbscDatepickerOptions {
  /**
   * Whether we are on a device with a small screen
   */
  isSmallDevice?: boolean;
  /**
   * onChange handler for DateAndTimePicker input
   */
  onChange: (e: string) => void;
  /**
   * The props to pass to the Mui input
   */
  inputProps?: TextFieldProps | FilledInputProps;
  /**
   * for cases where we only want to render a filled input rather than a TextField
   */
  filledInputOnly?: boolean;
  /**
   * The locale to set the DateAndTimePicker component to
   */
  localeCode?: string;
  /**
   * Translations for the buttons
   */
  localeProps: {
    okText: string;
    clearText: string;
    cancelText: string;
    toggleVisibilityText: string;
  };
  min?: string | number | Date | null | undefined;
  max?: string | number | Date | null | undefined;
}

// Note: See https://docs.mobiscroll.com/react/datepicker#options for more documentation

/**
 * Custom AskPorter datepicker component, using MUI TextField and Mobiscroll datepicker.
 *
 * main customization is the controls props, which is an array that controls available picker widgets.
 *  - Most of the time we just want ['calendar', 'time'].
 *  - dateWidget is the only component that currently uses just ['calendar'].
 *  - There are future uses planned for ['calendar', 'timegrid']
 *
 * ```tsx
 * <DateAndTimePicker
 *  value={'2020-01-01T00:00:00.000Z'}
 *  isSmallDevice={true}
 *  onChange={(e: string) => {}}
 *  localeProps={{
 *    okText: translate('OK'),
 *    clearText: translate('Clear'),
 *    cancelText: translate('Cancel'),
 *    toggleVisibilityText: translate('Toggle visibility'),
 *  }}
 *  inputProps={{
 *    label: 'Date',
 *    variant: 'outlined',
 *    id: 'date-datetime',
 *    inputProps: {
 *      key: new Date(afterValue).getTime(),
 *    },
 *   stepMinute={30}
 *   controls={['calendar', 'time']}
 *  }}
 * />
 * ```
 */
export const DateAndTimePicker: React.FC<React.PropsWithChildren<DateAndTimePickerProps>> = ({
  isSmallDevice = false,
  onChange,
  value,
  inputProps,
  filledInputOnly,
  localeProps,
  min = undefined,
  max = undefined,
  ...props
}) => {
  const anchor = React.useRef(null);
  const mobiscroll = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  // Note: By default, mobiscroll will use 'en-US' as the locale. I think UK date time is better.
  const localeCode = props?.localeCode || localStorage.getItem('selectedLanguage');
  const dateLocale = locale[localeCode] || locale[localeCode?.split('-')[0]] || locale['en-GB'];

  const onInputChange = (e: { target: { value: string } }) => {
    mobiscroll.current.setVal(e.target.value);
    setIsOpen(false);
  };

  const setNewDate = (sender?: 'onBlur' | 'onEnterKeyDown') => {
    const newTime = mobiscroll?.current?.getVal();
    const inputRef = inputProps.ref as React.MutableRefObject<HTMLInputElement>;

    const includesSender = ['onEnterKeyDown', 'onBlur'].includes(sender);

    if (!newTime) {
      // for certain events we want to trigger the onChange event because the user has cleared the date
      if (includesSender) onChange('');
      return;
    }
    // Note: Mobiscroll returns a string with current time seconds and milliseconds, and we don't want those.
    newTime.setSeconds(0.0);
    newTime.setMilliseconds(0.0);
    onChange(formatRFC3339(newTime, { fractionDigits: 3 }));
    setIsOpen(false);

    if (inputRef?.current?.querySelector('input').value === '' && includesSender) {
      onChange('');
    }
  };

  const handleClearTime = () => {
    onChange(null);
    mobiscroll.current.setVal(null);
    setIsOpen(false);
  };

  // If there is only 1 control type and it's not the 'time' one, the picker doesn't need
  // to confirm the date, can change the value and close.
  const onChangeHandler = () => props?.controls?.length === 1 && !props?.controls?.includes('time') && setNewDate();

  const onKeyDown = ({ key }: React.KeyboardEvent) => key === 'Enter' && setNewDate('onEnterKeyDown');

  const InputProps = {
    // destructure 'InputProps' if we are using the MUI 'TextField' component
    ...(!filledInputOnly ? (inputProps as TextFieldProps)?.InputProps : {}),
    ref: anchor,
    // Note: Mui calendar input type is 'tel', possibly because setting it to
    // 'date' causes the input to contain native browser date formatting.
    type: 'tel',
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label={localeProps.toggleVisibilityText}
          onClick={() => setIsOpen(true)}
          edge="end"
          disabled={props?.disabled}
        >
          <CalendarToday />
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <Datepicker
      ref={mobiscroll}
      theme="material"
      themeVariant="light"
      anchor={anchor.current}
      display="anchored"
      locale={dateLocale}
      showOnFocus={false}
      defaultValue={value && new Date(value)}
      showOnClick={false}
      returnFormat="jsdate"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onChange={(_, inst) => {
        // Note: onChange gets triggered occasionally when the user is typing in the input but
        // we only want to trigger onChange when the user has selected a date.
        if (typeof inst.value === 'string') return;
        onChangeHandler();
      }}
      touchUi={isSmallDevice}
      buttons={[
        { text: localeProps.okText, handler: setNewDate, keyCode: 'enter' },
        { text: localeProps.clearText, handler: handleClearTime },
        {
          text: localeProps.cancelText,
          handler: () => mobiscroll.current.close(),
          keyCode: 'esc',
        },
      ]}
      inputComponent={filledInputOnly ? FilledInput : TextField}
      {...props}
      inputProps={{
        ...inputProps,
        onChange: onInputChange,
        onKeyDown: onKeyDown,
        // if its a standard input then pass the Input props directly into it
        // otherwise, treat it like a TextField, where the InputProps need to be passed to InputProps
        ...(filledInputOnly ? InputProps : { InputProps }),
        onBlur: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          setNewDate('onBlur');
          InputProps?.onBlur?.(event);
        },
      }}
      min={min}
      max={max}
    />
  );
};
