import { StepConnector, stepConnectorClasses, styled } from '@mui/material';

export const ActivityStepConnector = styled(StepConnector)(({ theme }) => ({
  // Target the root container as this contains margin-left which we need to override as we
  // have increased the line width
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: '11px',
  },
  // Increase the line width and height
  [`& .${stepConnectorClasses.line}`]: {
    borderLeftWidth: '2px',
    height: '34px',
  },
  // Target the line when completed state is true
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
}));
