import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuthProvider } from '@askporter/auth-provider';
import { Button } from '@askporter/component-library';
import { TFunction } from '@askporter/config-provider';
import fetchExportByUid from '../../../../../utils/fetchExportByUid';
import { getFilterWithDefaultValues } from './getFilterWithDefaultValues';

interface ExportViewButtonProps {
  t: TFunction;
  uid: string;
}

/**
 * The ExportViewButton component is responsible for handle the navigation to the resource list page when the user clicks on the view button.
 */
export const ExportViewButton: React.FC<ExportViewButtonProps> = ({ t, uid }: ExportViewButtonProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userProfile } = useAuthProvider();

  const handleButtonClick = async () => {
    const exportDetail = await queryClient.fetchQuery(`exports/${uid}`, () => fetchExportByUid(uid));
    const { sort, freeText, type } = exportDetail;

    const filterWithDefaultValue = getFilterWithDefaultValues(exportDetail);
    const oboUserFilter =
      userProfile?.uid !== exportDetail?.createdBy?.uid ? `&oboUser=${exportDetail?.createdBy?.uid}` : '';

    const routeOptions = {
      TASK: '/app/tasks',
      ASSET: '/app/assets',
      USER: '/app/people/users',
      ORG: '/app/people/orgs',
      TEAM: '/app/people/teams',
    };
    // eslint-disable-next-line functional/immutable-data
    navigate(
      `${routeOptions[type]}?sort=${sort}&filter=${encodeURIComponent(
        JSON.stringify(filterWithDefaultValue),
      )}&freeText=${freeText || ''}${oboUserFilter}`,
    );
  };

  return <Button onClick={handleButtonClick}>{t('export:export_list:table:view_button')}</Button>;
};
