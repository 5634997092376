import React from 'react';
import { styled } from '@mui/material';
import { Typography, CustomTypographyProps } from '../Typography';

interface TypographyWithOverflowHandler {
  text: string;
  typographyProps?: Omit<CustomTypographyProps, 'children'>;
}

const StyledTypography = styled(Typography)(() => ({
  display: 'flex',
  flexDirection: 'row',
  '&::before, &::after': {
    display: 'inline-block',
    maxWidth: '50%',
    overflow: 'hidden',
    whiteSpace: 'pre',
  },
  '&::before': {
    content: 'attr(data-content-start)',
    textOverflow: 'ellipsis',
  },
  '&::after': {
    content: 'attr(data-content-end)',
    textOverflow: '""',
    direction: 'rtl',
  },
}));

/**
 * A typography component that handles text overflow by truncating the text and showing ellipses in the middle part of the text.
 */
export const TypographyWithOverflowHandler: React.FC<TypographyWithOverflowHandler> = ({ text, typographyProps }) => {
  const middleIndex = Math.ceil(text.length / 2);
  const [firstPart, secondPart] = [text.slice(0, middleIndex), text.slice(middleIndex)];

  return <StyledTypography {...typographyProps} noWrap data-content-start={firstPart} data-content-end={secondPart} />;
};
