import React, { SyntheticEvent, useState } from 'react';
import { Box, SxProps } from '@mui/system';
import { IconFolder } from '@askporter/utils';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const SVGInject = require('@iconfu/svg-inject');

export interface IconProps {
  /**
   * folder in https://github.com/askporter/media/tree/main/media/icons
   */
  folder?: IconFolder;
  /**
   * the full icon path excluding(usually from API responses), if this is is truthy then it will take
   * precedence over `folder`
   */
  fullPath?: string;
  /**
   * IconSize types, e.g. SM, MD or LG
   */
  size: IconSize;
  /**
   * targets the svg > g > g element and fills it with the provided color
   */
  fillColor?: string;
  /**
   * targets the stroke color of the svg > g > g element in the instance an icon uses it
   */
  isStrokeColor?: boolean;
  /**
   * an optional property to inherit the fill color from the parent
   */
  inheritFillColor?: boolean;
  /**
   *  color mask adds a translucent color block over the icon
   */
  colorMask?: ColorMaskProps;
  /**
   * the data-testid
   */
  dataTestId?: string;
  /**
   * error handler
   */
  onError?: (event: SyntheticEvent) => void;
}

export interface ColorMaskProps {
  size: number | string;
  color: string;
  borderRadius?: number | string;
}

/**
 * Renders an Icon from https://github.com/askporter/media/tree/main/media/icons
 */
export const Icon = ({
  folder,
  fullPath,
  size,
  fillColor,
  colorMask,
  dataTestId,
  onError,
  inheritFillColor = false,
  isStrokeColor = false,
}: IconProps): JSX.Element => {
  const [showImage, setShowImage] = useState(true);

  const fill: SxProps =
    fillColor || inheritFillColor
      ? {
          '& svg > g > g, svg > path': isStrokeColor
            ? { stroke: inheritFillColor ? 'inherit' : fillColor }
            : { fill: inheritFillColor ? 'inherit' : fillColor },
        }
      : undefined;

  let wrapperStyles: SxProps = { ...fill, width: size, height: size };

  if (colorMask) {
    wrapperStyles = {
      height: colorMask.size,
      width: colorMask.size,
      backgroundColor: colorMask.color ? undefined : 'background.default',
      borderRadius: colorMask.borderRadius || '4px',
    };
  }

  return (
    <Box
      data-testid={dataTestId || `icon-${folder || fullPath}`}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={wrapperStyles}
    >
      <img
        src={fullPath ? `${fullPath}${size}px.svg` : `/media/icons/${folder}/${size}px.svg`}
        style={!showImage ? { display: 'none' } : {}}
        onLoad={(e) => {
          SVGInject(e.target);
        }}
        onError={(e: SyntheticEvent) => {
          if (onError) onError(e);
          setShowImage(false);
        }}
        alt=""
      />

      {colorMask && (
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: colorMask.color,
            opacity: 0.25,
            width: colorMask.size,
            height: colorMask.size,
            borderRadius: colorMask?.borderRadius || '4px',
          }}
        />
      )}
    </Box>
  );
};

export default Icon;

export enum IconSize {
  SM = 16,
  MD = 24,
  LG = 32,
}
