import { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import {
  SearchAssetStatsActive,
  SearchAssetStatsDiscoverabilities,
  SearchResultsSummary,
  SearchStatsElement,
  SearchStatsGenericValueInner,
} from '@askporter/client-grieg-lyric';
import { useDebounce } from '@askporter/utils';
import { API } from '../api';

export interface GenericSearchResult {
  uid: string;
}

interface GenericSearchResponse<SearchResult extends GenericSearchResult> {
  summary?: SearchResultsSummary;
  stats?: {
    [key: string]:
      | Array<SearchAssetStatsActive>
      | Array<SearchStatsElement>
      | Array<SearchAssetStatsDiscoverabilities>
      | Array<SearchStatsGenericValueInner>;
  };
  results?: SearchResult[];
}

export interface UseSearchAutocompleteProps<Options> {
  status: 'loading' | 'error' | 'success' | 'idle';
  searchValue: string;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  request: boolean;
  setRequest: (value: boolean) => void;
  setSearchValue: (value: string) => void;
  setStrokeCount: (value: number) => void;
  strokeCount: number;
  options: Options;
}

export function useSearchAutocomplete<
  SearchResult extends GenericSearchResult,
  SearchResponse extends GenericSearchResponse<SearchResult>,
>({
  path,
}: {
  /**
   * The API path, e.g. assets/search
   */
  path: string;
}): UseSearchAutocompleteProps<SearchResult[]> {
  const [searchValue, setSearchValue] = useState('');
  const [request, setRequest] = useState(false);
  const [strokeCount, setStrokeCount] = useState(1);
  const freeText = useDebounce<string>(searchValue, 700);

  const search = {
    path,
    payload: {
      freeText,
      page: 1,
    },
  };

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, status } = useInfiniteQuery(
    [search],
    async ({ pageParam: page = 1 }) => {
      const response: { data: SearchResponse } = await API().post(search);
      return { response, nextPage: page + 1 };
    },
    {
      enabled: request && !!searchValue && freeText?.length > 2,
      getNextPageParam: ({ response, nextPage }) => {
        return response?.data?.summary?.totalPages >= nextPage ? nextPage : undefined;
      },
    },
  );

  const options: SearchResult[] =
    data?.pages && data?.pages.length > 0
      ? data.pages.reduce((acc, itm) => acc.concat([...itm.response.data.results]), [])
      : [];

  return {
    status,
    searchValue,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    request,
    setRequest,
    setSearchValue,
    setStrokeCount,
    strokeCount,
    options,
  };
}
