import { intervalToDuration } from 'date-fns';

interface TranslationStrings {
  days: string;
  hours: string;
  minutes: string;
  durationNotAvailable: string;
}

/**
 * Function for obtaining duration as a string e.g. 2 days 1 hour 4 mins
 * @param formattedDuration - Object containg days, hours, minutes
 * @param t - translation function
 * @param translationStrings - Object containing the translation parameters e.g. 'common:hour'
 * @returns string
 */
export const getFormattedDuration = (
  durationInSeconds: number,
  t: (key: string, options?: Record<string, number | string>) => string,
  translationStrings: TranslationStrings,
): string => {
  if (typeof durationInSeconds !== 'number' || durationInSeconds <= 0)
    return t(translationStrings.durationNotAvailable);

  const durationObject = intervalToDuration({ start: 0, end: durationInSeconds * 1000 });
  const formattedDuration = {
    days: durationObject.days,
    hours: durationObject.hours,
    minutes: durationObject.minutes,
  };

  // If days, hours, and minutes are all 0, return N/A since we don't want to show 0 seconds
  if (Object.values(formattedDuration).reduce((sum, value) => sum + (value || 0), 0) === 0)
    return t(translationStrings.durationNotAvailable);
  const days =
    formattedDuration?.days !== 0 &&
    t(translationStrings.days, {
      count: formattedDuration.days,
    });
  const hours =
    formattedDuration?.hours !== 0 &&
    t(translationStrings.hours, {
      count: formattedDuration.hours,
    });
  const minutes =
    formattedDuration?.minutes !== 0 &&
    t(translationStrings.minutes, {
      count: formattedDuration.minutes,
    });

  if (days) {
    return `${days}${hours ? ' ' + hours : ''}${minutes ? ' ' + minutes : ''}`;
  } else if (hours) {
    return `${hours}${minutes ? ' ' + minutes : ''}`;
  }
  return minutes ? minutes : '';
};
