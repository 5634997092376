import React, { Fragment, useState } from 'react';
import { Chip, CircularProgress, ListItem, Paper, Popper, SxProps, Theme, useMediaQuery } from '@mui/material';
import { useInfiniteQuery } from 'react-query';
import { SearchUserFilter, UserSearchResultExternal } from '@askporter/client-grieg-lyric';
import { Autocomplete, autocomplete, UserAvatar, Typography, AvatarSize } from '@askporter/component-library';
import { useDebounce, fullName, isAnonymousUser } from '@askporter/utils';
import doUserSearch from './utils/doUserSearch';

type MultipleUserPropUnion =
  | {
      /**
       * The selected user for the autocomplete component
       */
      value: UserSearchResultExternal;
      /**
       * Whether the autocomplete allows multiple users to be selected
       */
      isMultiple?: false;
      /**
       * The function to call when the selected value changes (e.g. A user option is selected)
       */
      onChange: (user: UserSearchResultExternal) => void;
    }
  | {
      /**
       * The selected users for the autocomplete component
       */
      value: UserSearchResultExternal[];
      /**
       * Whether the autocomplete allows multiple users to be selected
       */
      isMultiple: true;
      /**
       * The function to call when the selected value changes(e.g. A user option is selected)
       */
      onChange: (user: UserSearchResultExternal[]) => void;
    };

export type UserAutocompleteProps = {
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * The prefix for the id for the autocomplete component
   */
  idPrefix: string;
  /**
   * The label for the autocomplete component
   */
  label: string;
  /**
   * Whether the autocomplete component is disabled
   */
  disabled?: boolean;
  /**
   * The function to determine whether to show the user in the list of users returned in the search request
   */
  showUser?: (user: UserSearchResultExternal) => boolean;
  /**
   * The filter to apply to the search request
   */
  filter?: SearchUserFilter;
  /**
   * Whether there is an error which should be displayed
   */
  error?: boolean;
  /**
   * The helper text to display
   */
  helperText?: string;
  /**
   * The adornment to display at the top of the list (e.g. create an unregistered user button)
   */
  topOfListAdornment?: React.ReactNode;
  /**
   * Optional function to call when the input value changes (e.g. when the user types in the input field)
   */
  onInputChange?: (newInputValue: string) => void;
  /**
   * The function to render the option in the list of options
   */
  renderOption?: autocomplete['renderOption'];
  /**
   * The function to close the autocomplete drawer, primary used on small devices
   */
  closeComponent?: { shouldClose: boolean; reset: () => void };
  /**
   * Whether the autocomplete component is read only
   */
  readOnly?: boolean;
  /**
   * The text to display when the autocomplete component is read only
   */
  readOnlyText?: string;
  /**
   * Optional to set the input to use full width
   */
  fullWidth?: boolean;
  /**
   * Optional sx for the autocomplete component
   */
  sx?: SxProps<Theme>;
  /**
   * The page size to use for the search request
   */
  pageSize?: number;
  limitTags?: number;
  /**
   * on blur event handler
   */
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  showAvatar?: boolean;
} & MultipleUserPropUnion;

/**
 * An autocomplete component for selecting a user for a list of options with infinite scroll,
 * it hits `users/search` endpoint to get the list of users
 */
export const UserAutocomplete = ({
  idPrefix,
  label,
  disabled,
  filter = {},
  error = false,
  helperText,
  showUser = () => true,
  topOfListAdornment,
  onInputChange,
  renderOption,
  closeComponent,
  readOnly = false,
  readOnlyText = undefined,
  t,
  fullWidth = false,
  sx,
  pageSize,
  isMultiple,
  value,
  onChange,
  limitTags = -1,
  onBlur,
  showAvatar = true,
}: UserAutocompleteProps): JSX.Element => {
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [inputValue, setInputValue] = useState('');
  const userKeyword = useDebounce<string>(inputValue, 1000);
  const search = { freeText: userKeyword, filter, pageSize };
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, status } = useInfiniteQuery(
    [`users/search`, { search }],
    async ({ pageParam: page = 1 }) => {
      const response = await doUserSearch({
        ...search,
        page,
      });
      return { response, nextPage: page + 1 };
    },
    {
      enabled: userKeyword === '' || userKeyword?.length > 2,
      getNextPageParam: (data) => {
        return data?.response?.summary?.totalPages >= data?.nextPage ? data?.nextPage : undefined;
      },
    },
  );

  const getName = (user: UserSearchResultExternal) => {
    const isAnonymous = isAnonymousUser(user);

    return fullName(isAnonymous ? t('ns.common:people:anonymous_user') : user.givenName, user?.familyName);
  };

  const opts: UserSearchResultExternal[] =
    data?.pages && data?.pages.length > 0
      ? data.pages.reduce((acc, itm) => acc.concat([...itm.response.results.filter((user) => showUser(user))]), [])
      : [];

  const additionalOptions = isMultiple ? value : [value];
  const options = [...opts, ...additionalOptions];
  return (
    <Autocomplete
      multiple={isMultiple}
      limitTags={limitTags}
      closeComponent={closeComponent}
      error={error}
      helperText={helperText}
      isSmallDevice={isSmallDevice}
      idPrefix={idPrefix}
      label={label}
      disabled={disabled}
      getOptionLabel={(option: UserSearchResultExternal) => {
        return getName(option);
      }}
      options={options}
      value={value || null}
      isOptionEqualToValue={(opt: UserSearchResultExternal, val: UserSearchResultExternal) => {
        return opt?.uid === val?.uid;
      }}
      onChange={onChange}
      onBlur={onBlur}
      onInputChange={(_: React.SyntheticEvent, newInputValue: string) => {
        onInputChange && onInputChange(newInputValue);
        setInputValue(newInputValue);
      }}
      inputValue={inputValue}
      noOptionsText={
        <>
          {topOfListAdornment}
          <Typography px={4}>{t('ns.common:type_to_search')}</Typography>
        </>
      }
      loadingText={`${t('ns.common:loading')}`}
      ListboxProps={{
        onScroll: (e: React.SyntheticEvent) => {
          const listboxNode = e.currentTarget;
          if (hasNextPage) {
            if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              fetchNextPage();
            }
          }
        },
      }}
      startAdornment={
        !showAvatar ? null : isMultiple !== true && !!value && !!inputValue ? (
          <UserAvatar
            givenName={value?.givenName}
            familyName={value?.familyName}
            imagePath={value?.profilePhoto?.filePath}
          />
        ) : (
          <UserAvatar />
        )
      }
      renderOption={(props, user: UserSearchResultExternal, state, ownserState) => {
        return (
          <Fragment key={`${props.id}-${user.uid}`}>
            {user.uid === opts?.[0]?.uid && topOfListAdornment}
            {renderOption ? (
              renderOption(props, user, state, ownserState)
            ) : (
              <ListItem {...props} data-testid={`option-${props.id}`}>
                {getName(user)}
              </ListItem>
            )}
          </Fragment>
        );
      }}
      endAdornment={
        <>{(status === 'loading' || isFetchingNextPage) && <CircularProgress color="inherit" size={15} />}</>
      }
      PaperComponent={
        isSmallDevice ? ({ children }) => <Paper style={{ boxShadow: 'none' }}>{children}</Paper> : undefined
      }
      drawerSx={{
        '& .MuiAutocomplete-noOptions': {
          px: 0,
        },
      }}
      PopperComponent={(props) => (
        <Popper
          {...props}
          sx={{
            '& .MuiAutocomplete-noOptions': {
              px: 0,
            },
            maxHeight: '50px',
          }}
        />
      )}
      textFieldVariant={'outlined'}
      forcePopupIcon={false}
      filterOptions={(x) => x}
      readOnly={readOnly}
      readOnlyText={readOnlyText}
      fullWidth={fullWidth}
      sx={{
        '&.Mui-focused .MuiAutocomplete-tag': {
          maxWidth: '100%',
        },
        '& .MuiAutocomplete-tag': {
          maxWidth: '70%',
        },
        '&.Mui-focused .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
          minWidth: '30px',
        },
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
          minWidth: 0,
        },
        ...sx,
      }}
      renderTags={(values: UserSearchResultExternal[], getTagProps) => {
        if (isMultiple) {
          return values.map((user, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                avatar={
                  <UserAvatar
                    givenName={user?.givenName}
                    familyName={user?.familyName}
                    imagePath={user?.profilePhoto?.filePath}
                    avatarSize={AvatarSize.XS}
                  />
                }
                variant="outlined"
                label={getName(user)}
                key={key}
                sx={{
                  paddingX: 1,
                  '& .MuiChip-label': {
                    marginX: 2,
                  },
                  '& .MuiChip-deleteIcon': {
                    marginRight: 0,
                  },
                }}
                {...tagProps}
              />
            );
          });
        }
      }}
    />
  );
};
