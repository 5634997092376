import React from 'react';
import { Step, StepButton, Stepper } from '@mui/material'; /* 
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { TaskActivityStatus } from '@askporter/client-grieg-lyric'; */
import { ActivityStepConnector } from './components/ActivityStepConnector';
import { ActivityStepIcon } from './components/ActivityStepIcon';
import { ActivityStepLabel } from './components/ActivityStepLabel';

type Activity = {
  displayName: string;
  uid: string;
  isCompleted: boolean;
};

export interface ActivityStepperProps {
  activities: Array<Activity>;
  onClick: (activity: Activity) => void;
  activeStep: number;
}

export const ActivityStepper = ({ activities, activeStep, onClick }: ActivityStepperProps): JSX.Element => {
  return (
    <Stepper
      data-testid="activity-timeline"
      nonLinear
      orientation="vertical"
      activeStep={activeStep}
      connector={null} // We need to control the connector styles as it depends on the activity status, so we use our custom StepConnector below
    >
      {activities.map((activity, index) => {
        const isLast = index === activities.length - 1;

        return (
          <Step key={activity.uid} completed={activity.isCompleted}>
            <StepButton
              onClick={() => onClick(activity)}
              role="link"
              aria-current={activeStep === index ? 'page' : undefined}
            >
              <ActivityStepLabel
                componentsProps={{
                  label: {
                    'data-testid': `${activity.uid}-step-label`,
                  } as React.HTMLProps<HTMLSpanElement>,
                }}
                StepIconComponent={ActivityStepIcon}
              >
                {activity.displayName}
              </ActivityStepLabel>
            </StepButton>
            {!isLast && <ActivityStepConnector data-testid={`${activity.uid}-step-connector`} />}
          </Step>
        );
      })}
    </Stepper>
  );
};
