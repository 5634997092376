import { useLocation } from 'react-router-dom';

let _returnTo: string;

function useReturnTo(): { returnTo: string; setReturnTo: (fullPath: string) => void; setReturnToThisPage: () => void } {
  const location = useLocation();

  return {
    returnTo: _returnTo,
    setReturnTo: (path: string) => (_returnTo = path),
    setReturnToThisPage: () => {
      _returnTo = `${location.pathname}${location.search}`;
    },
  };
}

export default useReturnTo;
