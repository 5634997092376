import React, { ChangeEvent, useState } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

interface SearchSortProps {
  t: (key: string) => string;
  items: Array<{ label: string; value: string }>;
  onChange: (value: string) => void;
  initialValue: string;
}

const SearchSort = ({ t, items, onChange, initialValue }: SearchSortProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
    setSelectedValue(event.target.value);
  };

  return (
    <Box sx={{ mb: 6 }}>
      <FormControl variant="filled" sx={{ width: '100%', m: 0 }}>
        <InputLabel id="search-sort-label">{t('ns.common:sort_by_label')}</InputLabel>
        <Select labelId="search-sort-label" id="search-sort-select" value={selectedValue} onChange={handleChange}>
          {items.map(({ label, value }) => {
            return (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export { SearchSort };
