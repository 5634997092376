import React from 'react';
import { CircularProgress, SxProps, Theme, useMediaQuery } from '@mui/material';
import { Autocomplete, AutoCompleteProps, Typography } from '@askporter/component-library';
import { GenericSearchResult, UseSearchAutocompleteProps } from '../../hooks/useSearchAutocomplete';

export type SearchAutocompleteProps<SearchResult> = UseSearchAutocompleteProps<SearchResult[]> &
  Pick<
    AutoCompleteProps,
    'renderOption' | 'getOptionLabel' | 'label' | 'startAdornment' | 'sx' | 'multiple' | 'renderInput' | 'error'
  > & {
    disabled?: boolean;
    onChange: (searchResult: SearchResult | SearchResult[]) => void;
    value?: SearchResult | SearchResult[];
    t: (key: string, options?: Record<string, string | number>) => string;
    idPrefix?: string;
    isSmallDeviceOverride?: boolean;
    textFieldSx?: SxProps<Theme>;
  };

/**
 * Renders a SearchAutocomplete component which provides the basis for an infinite scrolling autocomplete with a number
 * of default behaviors and strings, such as loading and no opts text. This is intended to work in conjunction with the
 * useSearchAutocomplete hook, but for heavy customisation an equivalent set of props can be passed.
 */
function SearchAutocomplete<SearchResult extends GenericSearchResult>({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  onChange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  request,
  searchValue,
  setRequest,
  setSearchValue,
  setStrokeCount,
  status,
  strokeCount,
  t,
  idPrefix = 'search',
  isSmallDeviceOverride,
  textFieldSx = {},
  error,
  ...rest
}: SearchAutocompleteProps<SearchResult>): React.ReactElement {
  const isSmallDeviceQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true });
  const isSmallDevice = isSmallDeviceOverride === undefined ? isSmallDeviceQuery : isSmallDeviceOverride;

  const onScroll = (e: React.SyntheticEvent) => {
    const listboxNode = e.currentTarget;
    if (hasNextPage) {
      if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
        fetchNextPage();
      }
    }
  };

  return (
    <Autocomplete
      endAdornment={(status === 'loading' || isFetchingNextPage) && <CircularProgress color="inherit" size={15} />}
      filterOptions={(o) => o}
      forcePopupIcon={false}
      idPrefix={idPrefix}
      inputValue={searchValue}
      isOptionEqualToValue={(option: SearchResult, value: SearchResult) => option?.uid === value?.uid}
      isSmallDevice={isSmallDevice}
      ListboxProps={{ onScroll }}
      MobilePaperProps={{ onScroll }}
      loadingText={t('ns.common:loading')}
      noOptionsText={<Typography>{t('ns.common:type_to_search')}</Typography>}
      onBlur={() => {
        setRequest(false);
      }}
      onChange={(newValue: SearchResult) => {
        setRequest(false);
        return onChange(newValue);
      }}
      onFocus={() => {
        setStrokeCount(1);
      }}
      onInputChange={(_: React.SyntheticEvent, newInputValue: string) => {
        setSearchValue(newInputValue);
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (strokeCount === 1 && e.key === 'Backspace') {
          setRequest(false);
          setSearchValue('');
        }
        setStrokeCount(strokeCount + 1);
      }}
      onKeyPress={() => {
        setRequest(true);
      }}
      textFieldVariant="outlined"
      textFieldSx={textFieldSx}
      error={error}
      {...rest}
    />
  );
}

export default SearchAutocomplete;
