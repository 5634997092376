import React, { useState } from 'react';
import { useQueries } from 'react-query';
import { TeamSearchResult } from '@askporter/client-grieg-lyric';
import { API } from '../../api';
import { TeamAutocompleteProps, TeamAutocomplete } from '.';

export interface ManagedStateMultipleTeamAutocompleteProps
  extends Omit<Extract<TeamAutocompleteProps, { isMultiple: true }>, 'value' | 'isMultiple'> {
  selectedTeamUids: string[];
}

/**
 * A TeamAutocomplete component with its own managed state
 * it fetches the selected teams on page load, using selectedTeamUids array
 */
export const ManagedStateMultipleTeamAutocomplete: React.FC<ManagedStateMultipleTeamAutocompleteProps> = ({
  selectedTeamUids = [],
  ...props
}) => {
  const [selectedTeams, setSelectedTeams] = useState<TeamSearchResult[]>([]);

  useQueries(
    selectedTeamUids.map((uid) => ({
      queryKey: ['get-team-on-page-load', { selectedTeamUid: uid }],
      queryFn: () => API().get({ path: `teams/${uid}` }),
      // We only want to make the request on page load as the autocomplete won't have the users associated with the uids from the url
      enabled: Boolean(selectedTeamUids.length !== selectedTeams.length),
      onSuccess: (data: TeamSearchResult) => setSelectedTeams((prev) => [...prev, data]),
    })),
  );

  return (
    <TeamAutocomplete
      {...props}
      isMultiple
      value={selectedTeams}
      onChange={(value) => {
        setSelectedTeams(value);
        props.onChange(value);
      }}
    />
  );
};
