import React, { useState } from 'react';
import { Alert, AlertColor, AlertTitle, Box } from '@mui/material';

interface ErrorBoundaryAlertProps {
  title: string;
  error: Error;
  fallbackErrMessage: string;
  resetError?: () => void;
  pathname: string;
  severity?: AlertColor;
}

/**
 * Renders an error boundary alert and resets it on pathname change
 * @param title - error alert title
 * @param error - the error object
 * @param fallbackErrMessage - a fallback error string
 * @param resetError - function used to reset/clear error alert
 * @param pathname - the current location pathname
 * @param severity - optional severity AlertColor, defaults to error
 */
export const ErrorBoundaryAlert: React.FC<React.PropsWithChildren<ErrorBoundaryAlertProps>> = ({
  title,
  error,
  fallbackErrMessage,
  resetError,
  pathname,
  severity = 'error',
}: ErrorBoundaryAlertProps) => {
  const [errorSourcePathname] = useState(pathname);
  if (errorSourcePathname !== pathname && !!resetError) resetError();

  return (
    <Alert severity={severity} data-testid="error-alert-component">
      <AlertTitle>{title}</AlertTitle>
      <Box sx={{ wordBreak: 'break-word' }}>{error && error.toString() ? error.toString() : fallbackErrMessage}</Box>
    </Alert>
  );
};
