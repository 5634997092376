import React from 'react';
import { OrgSummaryExternal, SearchOrgResultExternal } from '@askporter/client-grieg-lyric';
import { ListItem, TextField } from '@askporter/component-library';
import { useSearchAutocomplete } from '../../hooks/useSearchAutocomplete';
import SearchAutocomplete, { SearchAutocompleteProps } from '../SearchAutocomplete';

type PermittedSearchAutocompleteProps = Pick<
  SearchAutocompleteProps<OrgSummaryExternal>,
  'sx' | 't' | 'label' | 'disabled' | 'multiple' | 'renderInput'
>;

type OrganisationMultiAutocompleteProps = PermittedSearchAutocompleteProps & {
  onChange: (org: OrgSummaryExternal[]) => void;
  value: OrgSummaryExternal[];
};

/** Renders an OrganisationMultiAutocomplete component */
const OrganisationMultiAutocomplete: React.FC<React.PropsWithChildren<OrganisationMultiAutocompleteProps>> = (
  props: OrganisationMultiAutocompleteProps,
) => {
  const autocompleteProps = useSearchAutocomplete<OrgSummaryExternal, SearchOrgResultExternal>({
    path: 'orgs/search',
  });

  return (
    <SearchAutocomplete<OrgSummaryExternal>
      {...props}
      {...autocompleteProps}
      isSmallDeviceOverride={false}
      multiple={true}
      idPrefix={'org-multi'}
      getOptionLabel={(org: OrgSummaryExternal) => org.name}
      renderOption={(liProps: React.HTMLAttributes<HTMLLIElement>, org: OrgSummaryExternal) => (
        <ListItem {...liProps} key={org?.uid} data-testid={`option-${liProps.id}`} primaryText={org.name} />
      )}
      renderInput={(params) => (
        <TextField
          data-testid="org-multi-autocomplete"
          {...params}
          label={props?.label}
          sx={{ '& .MuiChip-root > svg': { margin: 0 } }}
        />
      )}
    />
  );
};

export default OrganisationMultiAutocomplete;
