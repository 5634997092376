import React from 'react';
import { AssetSearchResultExternal } from '@askporter/client-grieg-lyric';
import { Avatar, AvatarSize, IconSize } from '@askporter/component-library';
import { getInitialsFromLongName } from '@askporter/utils';

interface AssetOptionAvatarProps {
  asset: Partial<AssetSearchResultExternal>;
}

/* A simple AssetOptionAvatar, encapsulating the logic for rendering a MD asset avatar only */
const AssetOptionAvatar: React.FC<React.PropsWithChildren<AssetOptionAvatarProps>> = ({
  asset: { media = [], assetType, title },
}: AssetOptionAvatarProps) => {
  const avatarSize = AvatarSize.MD;
  const iconSize = IconSize.MD;
  const imagePath = media?.[0]?.file?.filePath;
  const iconPath = assetType?.icon?.iconPath;
  const mode = imagePath ? 'image' : iconPath ? 'icon' : 'initials';

  return (
    <Avatar
      altText={title}
      avatar={{
        imagePath: imagePath ?? iconPath,
        size: { container: avatarSize, icon: iconSize },
        bgcolor: assetType?.icon?.iconColourMask,
        mode,
        variant: 'rounded',
        initials: getInitialsFromLongName(title),
      }}
    />
  );
};

export default AssetOptionAvatar;
