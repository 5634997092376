import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { SlaEscalationStatus } from '@askporter/client-grieg-lyric';
import { Icon, IconSize, Typography } from '@askporter/component-library';
import { escalationIcons } from '@askporter/utils';

interface EscalationStatusCheckboxLabelProps {
  label: string;
  status: Exclude<SlaEscalationStatus, 'NONE'>;
}

export const EscalationStatusCheckboxLabel: React.FC<React.PropsWithChildren<EscalationStatusCheckboxLabelProps>> = ({
  label,
  status,
}) => {
  const { palette } = useTheme();

  const iconColor = {
    [SlaEscalationStatus.L1]: palette.warning.main,
    [SlaEscalationStatus.L2]: palette.error.main,
    [SlaEscalationStatus.DoneL1]: palette.success.main,
    [SlaEscalationStatus.DoneL2]: palette.success.main,
  };

  return (
    <Stack direction="row" spacing={2}>
      <Icon size={IconSize.MD} fillColor={iconColor[status]} folder={escalationIcons[status]} isStrokeColor />
      <Typography paddingRight={4}>{label}</Typography>
    </Stack>
  );
};
