import { stepLabelClasses, StepLabel, styled } from '@mui/material';

export const ActivityStepLabel = styled(StepLabel)(({ theme }) => ({
  [`&.${stepLabelClasses.root}`]: {
    color: theme.palette.action.disabled,
    [`& .${stepLabelClasses.label}`]: {
      fontWeight: 600,
      fontSize: '14px',
      color: theme.palette.text.secondary,
    },
    [`& .${stepLabelClasses.active}`]: {
      color: theme.palette.primary.main,
    },
    [`& .${stepLabelClasses.completed}`]: {
      [`&.${stepLabelClasses.iconContainer}`]: {
        color: theme.palette.primary.main,
      },
    },
  },
}));
