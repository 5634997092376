import { AxiosError, AxiosResponse } from 'axios';
import { MutationOptions, useIsMutating, useMutation, useQueryClient } from 'react-query';
import { SessionState, useAuthProvider } from '@askporter/auth-provider';
import { ErrorModel, ProfileReadExternal, UserReadExternal, UserUpdateExternal } from '@askporter/client-grieg-lyric';
import { setCacheBuster, useTranslation } from '@askporter/config-provider';
import { API } from '../utils';

/** Setting the language has many facets, so this centralises language setting  */
function useSetLanguage(): {
  setLanguage: ({
    languageCode,
    profileMutationOpts,
  }?: {
    languageCode?: string;
    profileMutationOpts?: MutationOptions;
  }) => void;
  isLanguageConsistent: (profilePreferredLanguage: string) => boolean;
  isLoading: boolean;
} {
  const profileAPIPath = 'profile';
  const queryClient = useQueryClient();
  const isMutatingProfile = useIsMutating(['mutateProfileLanguage']);

  const { i18n } = useTranslation();
  const { sessionState } = useAuthProvider();
  const isAuthenticated = SessionState[sessionState] === 'valid' ? true : false;

  const localStorageLng = () => localStorage.getItem('selectedLanguage');
  const i18nLng = i18n?.language;

  const updateProfile = useMutation<AxiosResponse<ProfileReadExternal>, AxiosError<ErrorModel>, UserUpdateExternal>(
    (payload: UserUpdateExternal) => API().post({ path: profileAPIPath, payload }),
    {
      mutationKey: 'mutateProfileLanguage',
      onMutate: async (userProfileUpdate) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(profileAPIPath);

        // Snapshot the previous value
        const previousProfileData: UserReadExternal = queryClient.getQueryData(profileAPIPath);

        // Optimistically update to the new value
        queryClient.setQueryData(profileAPIPath, (old: UserReadExternal) => ({ ...old, ...userProfileUpdate }));

        // Return a context object with the snapshotted value
        return previousProfileData;
      },
      // Always refetch after error or success:
      onSettled: () => queryClient.invalidateQueries(profileAPIPath),
    },
  );

  /** Apply language code consistently, if a language code is not passed the local storage language will be used  */
  const setLanguage = async (
    { languageCode, profileMutationOpts = {} } = { languageCode: localStorageLng(), profileMutationOpts: {} },
  ) => {
    localStorage.setItem('selectedLanguage', languageCode);
    localStorage.setItem('languageChanged', new Date().toJSON());
    setCacheBuster();
    await i18n.changeLanguage(languageCode);

    // Invalidate the static tenant query to ensure the translations are updated to current language
    queryClient.invalidateQueries('static/tenant');

    isAuthenticated && isMutatingProfile === 0
      ? updateProfile.mutate({ preferredLanguage: languageCode }, profileMutationOpts)
      : null;
  };

  /** Check if the profile language is consistent with local storage and i18n */
  const isLanguageConsistent = (profilePreferredLng: string): boolean => {
    if (profilePreferredLng === localStorageLng() && profilePreferredLng === i18nLng) {
      return true;
    }

    return false;
  };

  return { setLanguage, isLanguageConsistent, isLoading: updateProfile.isLoading };
}

export default useSetLanguage;
