import React, { Fragment, useState } from 'react';
import { Chip, CircularProgress, ListItem, Paper, Popper, SxProps, Theme, useMediaQuery } from '@mui/material';
import { useInfiniteQuery } from 'react-query';
import { SearchTeamFilter, TeamSearchResult } from '@askporter/client-grieg-lyric';
import { Autocomplete, autocomplete, UserAvatar, Typography, AvatarSize } from '@askporter/component-library';
import { useDebounce } from '@askporter/utils';
import doTeamSearch from './utils/doTeamSearch';

type MultipleTeamPropUnion =
  | {
      /**
       * The selected team for the autocomplete component
       */
      value: TeamSearchResult;
      /**
       * Whether the autocomplete allows multiple teams to be selected
       */
      isMultiple?: false;
      /**
       * The function to call when the selected value changes (e.g. A team option is selected)
       */
      onChange: (team: TeamSearchResult) => void;
    }
  | {
      /**
       * The selected teams for the autocomplete component
       */
      value: TeamSearchResult[];
      /**
       * Whether the autocomplete allows multiple teams to be selected
       */
      isMultiple: true;
      /**
       * The function to call when the selected value changes(e.g. A team option is selected)
       */
      onChange: (team: TeamSearchResult[]) => void;
    };

export type TeamAutocompleteProps = {
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * The prefix for the id for the autocomplete component
   */
  idPrefix: string;
  /**
   * The label for the autocomplete component
   */
  label: string;
  /**
   * Whether the autocomplete component is disabled
   */
  disabled?: boolean;
  /**
   * The function to determine whether to show the user in the list of users returned in the search request
   */
  showTeam?: (user: TeamSearchResult) => boolean;
  /**
   * The filter to apply to the search request
   */
  filter?: SearchTeamFilter;
  /**
   * Whether there is an error which should be displayed
   */
  error?: boolean;
  /**
   * The helper text to display
   */
  helperText?: string;
  /**
   * The adornment to display at the top of the list (e.g. create an unregistered user button)
   */
  topOfListAdornment?: React.ReactNode;
  /**
   * Optional function to call when the input value changes (e.g. when the user types in the input field)
   */
  onInputChange?: (newInputValue: string) => void;
  /**
   * The function to render the option in the list of options
   */
  renderOption?: autocomplete['renderOption'];
  /**
   * The function to close the autocomplete drawer, primary used on small devices
   */
  closeComponent?: { shouldClose: boolean; reset: () => void };
  /**
   * Whether the autocomplete component is read only
   */
  readOnly?: boolean;
  /**
   * The text to display when the autocomplete component is read only
   */
  readOnlyText?: string;
  /**
   * Optional to set the input to use full width
   */
  fullWidth?: boolean;
  /**
   * Optional sx for the autocomplete component
   */
  sx?: SxProps<Theme>;
  /**
   * The page size to use for the search request
   */
  pageSize?: number;
} & MultipleTeamPropUnion;

/**
 * An autocomplete component for selecting a team for a list of options with infinite scroll,
 * it hits `teams/search` endpoint to get the list of teams
 */
export const TeamAutocomplete = ({
  idPrefix,
  label,
  disabled,
  filter = {},
  error = false,
  helperText,
  showTeam = () => true,
  topOfListAdornment,
  onInputChange,
  renderOption,
  closeComponent,
  readOnly = false,
  readOnlyText = undefined,
  t,
  fullWidth = false,
  sx,
  pageSize,
  isMultiple,
  value,
  onChange,
}: TeamAutocompleteProps): JSX.Element => {
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [inputValue, setInputValue] = useState('');
  const teamKeyword = useDebounce<string>(inputValue, 1000);
  const search = { freeText: teamKeyword, filter, pageSize };
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, status } = useInfiniteQuery(
    [`users/search`, { search }],
    async ({ pageParam: page = 1 }) => {
      const response = await doTeamSearch({
        ...search,
        page,
      });
      return { response, nextPage: page + 1 };
    },
    {
      enabled: teamKeyword === '' || teamKeyword?.length > 2,
      getNextPageParam: (data) => {
        return data?.response?.summary?.totalPages >= data?.nextPage ? data?.nextPage : undefined;
      },
    },
  );

  const opts: TeamSearchResult[] =
    data?.pages && data?.pages.length > 0
      ? data.pages.reduce((acc, itm) => acc.concat([...itm.response.results.filter((user) => showTeam(user))]), [])
      : [];

  return (
    <Autocomplete
      multiple={isMultiple}
      closeComponent={closeComponent}
      error={error}
      helperText={helperText}
      isSmallDevice={isSmallDevice}
      idPrefix={idPrefix}
      label={label}
      disabled={disabled}
      getOptionLabel={(option: TeamSearchResult) => option.name}
      options={[...opts]}
      value={value || null}
      isOptionEqualToValue={(opt: TeamSearchResult, val: TeamSearchResult) => {
        return opt?.uid === val?.uid;
      }}
      onChange={onChange}
      onInputChange={(_: React.SyntheticEvent, newInputValue: string) => {
        onInputChange && onInputChange(newInputValue);
        setInputValue(newInputValue);
      }}
      inputValue={inputValue}
      noOptionsText={
        <>
          {topOfListAdornment}
          <Typography px={4}>{t('ns.common:type_to_search')}</Typography>
        </>
      }
      loadingText={`${t('ns.common:loading')}`}
      ListboxProps={{
        onScroll: (e: React.SyntheticEvent) => {
          const listboxNode = e.currentTarget;
          if (hasNextPage) {
            if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              fetchNextPage();
            }
          }
        },
      }}
      startAdornment={
        isMultiple !== true && !!value && !!inputValue ? (
          <UserAvatar longName={value.name} isMultiple />
        ) : (
          <UserAvatar isMultiple />
        )
      }
      renderOption={(props, team: TeamSearchResult, state, ownserState) => {
        return (
          <Fragment key={`${props.id}-${team.uid}`}>
            {renderOption ? (
              renderOption(props, team, state, ownserState)
            ) : (
              <ListItem {...props} data-testid={`option-${props.id}`}>
                {team.name}
              </ListItem>
            )}
          </Fragment>
        );
      }}
      endAdornment={
        <>{(status === 'loading' || isFetchingNextPage) && <CircularProgress color="inherit" size={15} />}</>
      }
      PaperComponent={
        isSmallDevice ? ({ children }) => <Paper style={{ boxShadow: 'none' }}>{children}</Paper> : undefined
      }
      drawerSx={{
        '& .MuiAutocomplete-noOptions': {
          px: 0,
        },
      }}
      PopperComponent={(props) => (
        <Popper
          {...props}
          sx={{
            '& .MuiAutocomplete-noOptions': {
              px: 0,
            },
          }}
        />
      )}
      textFieldVariant={'outlined'}
      forcePopupIcon={false}
      filterOptions={(x) => x}
      readOnly={readOnly}
      readOnlyText={readOnlyText}
      fullWidth={fullWidth}
      sx={sx}
      renderTags={(values: TeamSearchResult[], getTagProps) => {
        if (isMultiple) {
          return values.map((team, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                avatar={<UserAvatar longName={team.name} avatarSize={AvatarSize.XS} />}
                variant="outlined"
                label={team.name}
                key={key}
                sx={{
                  paddingX: 1,
                  '& .MuiChip-label': {
                    marginX: 2,
                  },
                  '& .MuiChip-deleteIcon': {
                    marginRight: 0,
                  },
                }}
                {...tagProps}
              />
            );
          });
        }
      }}
    />
  );
};
