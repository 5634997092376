import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Divider, Box, ListItemIcon, ListItemText, Drawer, List, ListItem, ListItemButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { TenantMenuItemsExternalInner } from '@askporter/client-grieg-lyric';
import { IconFolder } from '@askporter/utils';
import { Icon, IconSize } from '../../../Icon';
import { disabledNavStyle, IconLink, NavbarLink } from '../../utils';
import MenuListItems from './components/MenuListItems';
import { getMainItems, getSecondaryItems } from './utils';

interface MobileAccountMenuProps {
  t: (key: string) => string;
  handleSignOut: () => void;
  mainItems: Array<IconLink | NavbarLink>;
  setProfileMenu: (event: null) => void;
  logoComponent: React.ReactNode;
  open: boolean;
  tenantNavButtons: TenantMenuItemsExternalInner[];
}

const MobileAccountMenu: React.FC<MobileAccountMenuProps> = ({
  t,
  handleSignOut,
  mainItems,
  setProfileMenu,
  logoComponent,
  open,
  tenantNavButtons,
}: MobileAccountMenuProps) => {
  const mainNavItems = getMainItems(mainItems);
  const secondaryItems = getSecondaryItems(mainItems);
  const location = useLocation();
  const { isDisabled, disabledNavStyles } = disabledNavStyle(location.pathname, '/app/account');
  return (
    <>
      <Drawer open={open} onClose={() => setProfileMenu(null)}>
        <List data-testid="menu" sx={{ width: '100vw' }}>
          <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ height: '48px' }}>{logoComponent}</Box>
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              <CloseIcon onClick={() => setProfileMenu(null)} />
            </ListItemIcon>
          </ListItem>
          <MenuListItems navItems={mainNavItems} t={t} />
          <Divider />
          <ListItemButton
            to="/app/account"
            state={{ from: location.pathname }}
            component={Link}
            disabled={isDisabled}
            data-testid="my-account-btn"
            sx={{ ...disabledNavStyles }}
            divider={true}
          >
            <ListItemIcon>
              <PersonOutlineIcon />
            </ListItemIcon>
            <ListItemText>{t('global:header:nav:my_account')}</ListItemText>
          </ListItemButton>
          <MenuListItems navItems={secondaryItems} t={t} divider={true} />
          {tenantNavButtons?.map((item, idx) => (
            <ListItem disablePadding key={idx}>
              <ListItemButton
                component={'a'}
                href={item?.linkAddress}
                target={item?.openInNew ? '_blank' : undefined}
                sx={{
                  width: '200px',
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <ListItemIcon>
                  <Icon size={IconSize.MD} folder={item?.iconPath as IconFolder} />
                </ListItemIcon>
                <ListItemText>{item?.linkTitle}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
          {tenantNavButtons?.length > 0 && <Divider />}
          <ListItem sx={{ position: 'fixed', bottom: '0px' }} disablePadding>
            <ListItemButton aria-label={t('common:sign_out:button')} data-testid="sign-out" onClick={handleSignOut}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>{t('global:header:nav:logout')}</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default MobileAccountMenu;
