export {
  fullNavButtons,
  fullNavButtonLinks,
  iconNavButtons,
  createIconNavButtonLinks,
  menuOnlyNavButtons,
  menuOnlyNavButtonLinks,
} from './links';
export type { IconLink, NavbarLink, FullNavButton, IconNavButton, MenuOnlyNavButton } from './links';
export { useResponsiveProperties } from './useResponsiveProperties';
export { checkIfNavButtonIsAllowed } from './checkIfNavButtonIsAllowed';
export const isNavDisabled = (currentLocation: string, itemHref?: string) => {
  if (itemHref === '/app/account') return currentLocation.includes('account');
  if (itemHref === '/app/admin-config' && currentLocation.includes('admin-config')) return true;
  if (currentLocation === itemHref) return true;
  return false;
};
export const disabledNavStyle = (currentLocation: string, itemHref?: string) => {
  const isDisabled = isNavDisabled(currentLocation, itemHref);
  return {
    isDisabled,
    disabledNavStyles: {
      '&.Mui-disabled': {
        opacity: isDisabled ? 0.6 : 1,
      },
    },
  };
};
export function navButtonIsActive(navButtonHref: string, locationPathname: string) {
  // e.g. /app/tasks === /app/tasks or /app/tasks/1 should both return true
  // /app/tasks === /app/other should return false
  const firstTwoButtonRoutes = navButtonHref.split('/').slice(0, 3).join('');
  const firstTwoLocationRoutes = locationPathname.split('/').slice(0, 3).join('');
  return locationPathname === navButtonHref || firstTwoButtonRoutes === firstTwoLocationRoutes;
}
