import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { UserSearchResultExternal } from '@askporter/client-grieg-lyric';
import { API } from '../../api';
import { UserAutocompleteProps, UserAutocomplete } from '.';

export interface ManagedStateUserAutocompleteProps
  extends Omit<Extract<UserAutocompleteProps, { isMultiple?: false }>, 'value'> {
  selectedUserUid: string;
}

/**
 * A UserAutocomplete component with its own managed state
 * it fetches the selected user on page load, using the provided selectedUserUid
 */
export const ManagedStateUserAutocomplete: React.FC<ManagedStateUserAutocompleteProps> = ({
  selectedUserUid,
  ...props
}) => {
  const [selectedUser, setSelectedUser] = useState<UserSearchResultExternal | null>(null);

  useQuery(['get-user-on-page-load', { selectedUserUid }], () => API().get({ path: `users/${selectedUserUid}` }), {
    enabled: Boolean(selectedUserUid && !selectedUser),
    onSuccess: (data) => setSelectedUser(data),
  });

  return (
    <UserAutocomplete
      {...props}
      value={selectedUser}
      onChange={(value: UserSearchResultExternal) => {
        setSelectedUser(value);
        props.onChange(value);
      }}
    />
  );
};
