import React from 'react';
import { AssetSearchResultExternal } from '@askporter/client-grieg-lyric';
import { ListItem } from '@askporter/component-library';
import { generateAssetTitle } from '@askporter/utils';
import AssetOptionAvatar from '../AssetOptionAvatar';

export interface AssetRenderOptionProps {
  liProps: React.HTMLAttributes<HTMLLIElement>;
  asset: AssetSearchResultExternal;
}

/** Renders a AssetRenderOption component */
const AssetRenderOption: React.FC<React.PropsWithChildren<AssetRenderOptionProps>> = ({
  liProps,
  asset,
}: AssetRenderOptionProps) => {
  return (
    <ListItem
      {...liProps}
      data-testid={`option-${liProps.id}`}
      startComponent={<AssetOptionAvatar asset={asset} />}
      primaryText={generateAssetTitle(asset?.title, asset?.location?.postalCode)}
      secondaryText={asset?.assetType?.displayName}
    />
  );
};

export default AssetRenderOption;
