import React from 'react';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { Icon, IconSize, Typography } from '@askporter/component-library';

export interface ThreeStateCheckboxProps extends CheckboxProps {
  label: string;
  state: boolean | 'indeterminate';
  iconPath?: string;
  formControlLabelSx?: SxProps;
}

/**
 * Renders a ThreeStateCheckbox component
 * @param label - checkbox item label text
 * @param state - true (checked), false (unchecked) or indeterminate (true but indeterminate so displays a dash instead of a tick)
 * @param iconPath - icon to display before the label text
 * @param formControlLabelSx - styles for the FormControlLabel component that wraps the checkbox
 */
const ThreeStateCheckbox: React.FC<React.PropsWithChildren<ThreeStateCheckboxProps>> = ({
  label,
  state,
  iconPath,
  formControlLabelSx,
  ...checkBoxProps
}: ThreeStateCheckboxProps) => {
  const checked = state === 'indeterminate' || state === true ? true : false;
  const indeterminate = state === 'indeterminate' ? true : false;

  return (
    <FormControlLabel
      value={checked}
      control={<Checkbox color="primary" checked={checked} indeterminate={indeterminate} {...checkBoxProps} />}
      labelPlacement="end"
      label={
        <Box display="flex" flexWrap="nowrap" alignItems="center">
          {!!iconPath && (
            <Box sx={{ mr: 2 }}>
              <Icon size={IconSize.LG} fullPath={iconPath} />
            </Box>
          )}
          <Typography>{label}</Typography>
        </Box>
      }
      sx={formControlLabelSx}
    />
  );
};

export default ThreeStateCheckbox;
