import React from 'react';
import { Alert, Chip, useTheme } from '@mui/material';
import { TeamSummary, UserSummaryExternal, SlaEscalationStatus } from '@askporter/client-grieg-lyric';
import { fullName } from '@askporter/utils';
import { AvatarSize } from '../../Avatar';
import { UserAvatar } from '../../UserAvatar';
import { getTaskEscalationAlertProps } from './utils';

type EscalatedToUnion =
  | {
      escalatedToType: 'user';
      escalatedTo: UserSummaryExternal;
    }
  | {
      escalatedToType: 'team';
      escalatedTo: TeamSummary;
    }
  | {
      escalatedToType: 'none';
      escalatedTo: null;
    };

export type TaskEscalationAlertProps = {
  t: (key: string) => string;
  escalationStatus: SlaEscalationStatus;
  escalatedUser: UserSummaryExternal | undefined;
  escalatedTeam: TeamSummary | undefined;
};

export const TaskEscalationAlert: React.FC<TaskEscalationAlertProps> = ({
  t,
  escalationStatus,
  escalatedTeam,
  escalatedUser,
}) => {
  const {
    custom: { radius },
  } = useTheme();
  const taskEscalationAlertProps = getTaskEscalationAlertProps({ escalationStatus, t });

  if (!taskEscalationAlertProps) return null;

  const { escalatedTo, escalatedToType }: EscalatedToUnion = {
    ...(escalatedUser?.uid
      ? {
          escalatedTo: escalatedUser,
          escalatedToType: 'user',
        }
      : escalatedTeam?.uid
      ? {
          escalatedTo: escalatedTeam,
          escalatedToType: 'team',
        }
      : {
          escalatedTo: null,
          escalatedToType: 'none',
        }),
  };

  const { escalationSeverity, escalationIcon, escalationLevelText } = taskEscalationAlertProps || {};

  return (
    <Alert sx={{ borderRadius: radius?.sm }} severity={escalationSeverity} icon={false}>
      <Chip
        sx={{ borderRadius: radius?.sm }}
        icon={escalationIcon}
        color={escalationSeverity}
        label={escalationLevelText}
      />
      {escalatedTo && (
        <Chip
          data-testid="escalated-to-chip"
          sx={{
            borderRadius: radius?.sm,
            marginLeft: 3,
            '& .MuiChip-label': {
              paddingLeft: 1,
            },
          }}
          icon={
            <UserAvatar
              givenName={escalatedToType === 'user' ? escalatedTo?.givenName : undefined}
              familyName={escalatedToType === 'user' ? escalatedTo?.familyName : undefined}
              imagePath={escalatedToType === 'user' ? escalatedTo?.profilePhoto?.filePath : undefined}
              longName={escalatedToType === 'team' ? escalatedTo?.name : undefined}
              dataTestId={escalatedToType === 'user' ? 'escalated-to-user-avatar' : 'escalated-to-team-avatar'}
              avatarSize={AvatarSize.XS}
            />
          }
          color={escalationSeverity}
          label={
            escalatedToType === 'user' ? fullName(escalatedTo?.givenName, escalatedTo?.familyName) : escalatedTo?.name
          }
        />
      )}
    </Alert>
  );
};
