import React, { ReactElement } from 'react';
import { ListItem as ListItemWrapper, ListItemIcon, ListItemText, Box } from '@mui/material';
import { Typography, CustomTypographyVariant, Chip, OptionsDropdown, OptionsParams } from '../../';

export interface LinkedListItemProps {
  /**
   * title / header copy
   */
  title: string;
  /**
   * the variant to use to render the title with
   */
  titleVariant?: CustomTypographyVariant;
  /**
   * the subtitle
   */
  subTitle?: string | ReactElement;
  /**
   * the icon to show in the card
   */
  icon: ReactElement;
  /**
   * whether we are in mobile breakpoint
   */
  isSmallDevice: boolean;
  /**
   * optional, note: this only renders if isSupplier is falsy, it defines the list of options to
   * show when clicking on the ellipsis button
   */
  optionsParams?: OptionsParams;
  /**
   * optional, the status text to show next to the title
   */
  status?: string;
  /**
   * optional, the status chip text to show next to the title
   */
  tagText?: string;
  /**
   * optional, whether to enable/disable the ellipsis button
   */
  actionsEnabled?: boolean;
  /**
   * child component(s) which will be rendered as the last item in the ListItemWrapper
   */
  children?: JSX.Element | JSX.Element[];
}

/**
 * Renders a generic list component
 */
export const LinkedListItem: React.FC<React.PropsWithChildren<LinkedListItemProps>> = ({
  title,
  titleVariant,
  subTitle,
  icon,
  isSmallDevice,
  optionsParams,
  status,
  tagText,
  actionsEnabled = true,
  children,
}: LinkedListItemProps) => {
  const isOptionsDropdownVisible = actionsEnabled && optionsParams?.options && optionsParams?.options?.length >= 1;
  return (
    <ListItemWrapper
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        alignItems: 'center',
        padding: 0,
        position: 'relative',
      }}
      data-testid="list-item"
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>
        {subTitle && (
          <Typography color="textSecondary" variant="body2">
            {subTitle}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            alignItems: 'center',
          }}
        >
          <Typography variant={titleVariant}>{title}</Typography>
          {tagText ? <Chip variant="outlined" label={tagText} data-testid="list-item-tag" /> : ''}
          {Boolean(status) && (
            <Typography
              variant="body2"
              sx={{
                border: '2px solid',
                borderColor: 'divider',
                borderRadius: '0.25rem',
                padding: 1,
              }}
            >
              {status}
            </Typography>
          )}
        </Box>
      </ListItemText>
      {isOptionsDropdownVisible ? (
        <OptionsDropdown isSmallDevice={isSmallDevice} optionsParams={optionsParams} />
      ) : null}
      {children}
    </ListItemWrapper>
  );
};
