import React, { useState } from 'react';
import { MenuItemProps, FormHelperText } from '@mui/material';
import { Controller, ControllerProps } from 'react-hook-form';
import { useQuery } from 'react-query';
import { SearchOrgFilter, SearchOrgResultExternal } from '@askporter/client-grieg-lyric';
import { Autocomplete } from '@askporter/component-library';
import { SelectItem, generateDropDownList, useDebounce } from '@askporter/utils';
import { API } from '../../api';

interface ControlledOrgAutocompleteProps extends Omit<ControllerProps, 'render'> {
  label: string;
  error: boolean;
  errorMessage: string;
  isSmallDevice: boolean;
  orgSearchfilter?: SearchOrgFilter;
}

/**
 * Extends the Autocomplete to create ControlledOrgAutocomplete
 * @param label - field label
 * @param error - current error state, should be true if there is an error
 * @param errorMessage -  the error string to display
 * @param isSmallDevice - is a small device or not
 * @param orgSearchFilter - optional filter property to be passed into the orgs search query
 */
const ControlledOrgAutocomplete: React.FC<React.PropsWithChildren<ControlledOrgAutocompleteProps>> = ({
  label,
  error,
  errorMessage,
  isSmallDevice,
  orgSearchfilter: filter,
  ...controllerProps
}: ControlledOrgAutocompleteProps) => {
  const [orgSearchValue, setOrgSearchValue] = useState('');
  const debouncedOrg = useDebounce<string>(orgSearchValue, 700);
  const postOrgSearchKey = {
    path: 'orgs/search',
    payload: {
      freeText: debouncedOrg,
      page: 1,
      // otherwise it could be set to 'undefined' and reported as en error in 'setupGriegIntercepts'
      ...(filter ? { filter } : {}),
    },
  };
  const { data: { data: orgSearch = {} } = {} } = useQuery<{
    data: SearchOrgResultExternal;
  }>([postOrgSearchKey], () => API().post({ ...postOrgSearchKey }), {
    enabled: debouncedOrg === '' || debouncedOrg?.length > 2,
  });

  const orgAutocompleteOptions = generateDropDownList(orgSearch?.results || [], 'urn', 'name');

  return (
    <>
      <Controller
        defaultValue={null}
        {...controllerProps}
        render={({ field }) => (
          <Autocomplete
            {...field} // pass the ref, value & onChange through to the autocomplete
            idPrefix={controllerProps.name}
            isSmallDevice={isSmallDevice}
            error={error}
            label={label}
            options={orgAutocompleteOptions}
            isOptionEqualToValue={(
              option: SelectItem<MenuItemProps['value']>,
              value: SelectItem<MenuItemProps['value']>,
            ) => option?.value === value?.value}
            getOptionLabel={(org: SelectItem<MenuItemProps['value']>) => org?.label}
            inputValue={orgSearchValue}
            onInputChange={(_: React.SyntheticEvent, newInputValue: string) => {
              setOrgSearchValue(newInputValue);
            }}
            freeSolo
            filterOptions={(o) => o}
          />
        )}
      />
      {error && (
        <FormHelperText error={true} data-testid={`${controllerProps.name}-error`}>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};

export default ControlledOrgAutocomplete;
