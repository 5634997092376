import React from 'react';
import { Rum as APRum } from '@askporter/rum';
import CoreNavigation from '../../components/CoreNavigation';
import { config } from '../../utils/config';

const rumConfig = {
  IS_CI: config.IS_CI,
  IS_SERVED_FROM_MOCK_SERVER: config.IS_SERVED_FROM_MOCK_SERVER,
  BUILD_TARGET_ENV: config.BUILD_TARGET_ENV,
  NEXT_RELEASE_VERSION: config.NEXT_RELEASE_VERSION,
  ENABLE_LOCAL_RUM: config.ENABLE_LOCAL_RUM,
};

interface RumProps {
  children: JSX.Element[] | JSX.Element;
}

const Rum: React.FC<React.PropsWithChildren<RumProps>> = ({ children }) => {
  return (
    <APRum config={rumConfig} navigation={<CoreNavigation />}>
      {children}
    </APRum>
  );
};

export default Rum;
