import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps as MuiBreadcrumbsProps } from '@mui/material';
import { BreadcrumbProps } from '../Breadcrumb';

export type BreadcrumbsProps = MuiBreadcrumbsProps &
  Pick<BreadcrumbProps, 'color' | 'variant'> & { children?: JSX.Element[] | JSX.Element };

/**
 * Renders separated Breadcrumbs, acts as a a wrapper for the Breadcrumb and provides a simple way to set color and
 * variant at the group level.
 */
export const Breadcrumbs: React.FC<React.PropsWithChildren<BreadcrumbsProps>> = ({
  variant,
  color,
  children,
  ...muiBreadcrumbsProps
}: BreadcrumbsProps) => {
  /**
   * Adds the styles to the breadcrumb while ensuring any existing styles are not overwritten.
   */
  const addProps = (props: BreadcrumbProps, key: string) => ({
    variant,
    color,
    ...props,
    key: `breadcrumb_child_${key}`,
  });
  return (
    <MuiBreadcrumbs
      {...muiBreadcrumbsProps}
      separator={<NavigateNextIcon fontSize="small" sx={{ color: '#D0D5DD' }} />}
    >
      {Array.isArray(children)
        ? React.Children.map(children, (child, idx) => {
            return React.cloneElement(child, addProps(child.props, `${idx}_${child?.key}`));
          })
        : !!children && React.cloneElement(children, addProps(children.props, `${children?.key}`))}
    </MuiBreadcrumbs>
  );
};
