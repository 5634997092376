import React, { useState } from 'react';
import { Alert } from '@mui/material';
import { TaskSearchResultExternal } from '@askporter/client-grieg-lyric';
import { captureException } from '@askporter/exception-logger';
import { Button } from '../Buttons/Button';
import { Card } from '../Card';
import { Dialog } from '../Dialog';
import { TaskCardContents } from './TaskCardContents';
import { TaskEscalationAlert } from './TaskEscalationAlert';

export interface TaskCardProps {
  /**  The mode for the task card */
  mode: 'list' | 'detailed';
  /**  The translation method */
  t: (key: string) => string;
  /**  Callback for when the card is clicked */
  onClick?: () => void;
  /**  The currently selected task */
  selectedTask?: TaskSearchResultExternal;
  /**  Whether this task card is selected or not */
  isSelected?: boolean;
  /**  Whether the card is loading */
  isLoading?: boolean;
  /**  Permissions regarding the user accessing the card data */
  permissions?: Record<string, boolean>;
  /**  Callback for removing currently selected task */
  onRemoveSelectedTask?: () => void;
  /**  The task details react component */
  taskDetails?: React.ReactNode;
  /** If true, the breadcrumb props will be calculated dynamically (using calculate breadcrumbs and breakpoints) */
  dynamicBreadcrumbsSize?: boolean;
  /** Whether task card display is a single status only */
  slaFilter?: 'RESPONSE' | 'RESOLUTION';
  href?: string;
  isManager: boolean;
}

export const TaskCard: React.FC<React.PropsWithChildren<TaskCardProps>> = ({
  mode,
  selectedTask,
  isSelected,
  t,
  onClick,
  isLoading,
  permissions,
  onRemoveSelectedTask,
  taskDetails,
  dynamicBreadcrumbsSize,
  slaFilter,
  href,
  isManager = false,
}) => {
  const [showRemoveTaskConfirmation, setShowRemoveTaskConfirmation] = useState(false);

  return (
    <>
      <Card
        dataTestId={`${isSelected ? 'selected-' : ''}task-card`}
        cardMode={!isLoading && mode === 'list' ? 'radio' : 'standard'}
        readOnly={(!onClick && mode === 'detailed') || isLoading ? true : false}
        isSelected={isSelected && mode === 'list'}
        value={selectedTask?.uid}
        onClick={onClick}
        href={href}
        closeButton={
          isSelected &&
          mode === 'detailed' && {
            ariaLabel: t('ns.inbound_call_triage:remove_task'),
            onClose: () => setShowRemoveTaskConfirmation(true),
          }
        }
        {...(taskDetails &&
          isSelected &&
          mode === 'detailed' && {
            expandableSection: {
              title: t(`ns.inbound_call_triage:task_card:task_details`),
              body: taskDetails,
            },
          })}
      >
        {isManager && (
          <TaskEscalationAlert
            t={t}
            escalationStatus={selectedTask?.sla?.escalation?.status}
            escalatedTeam={selectedTask?.escalatedTeam}
            escalatedUser={selectedTask?.escalatedUser}
          />
        )}
        <TaskCardContents
          captureException={captureException}
          taskType={selectedTask?.taskType}
          title={selectedTask?.title}
          taskRef={selectedTask?.taskRef}
          status={selectedTask?.status}
          sla={selectedTask?.sla}
          closedAt={selectedTask?.closedAt}
          principalAsset={selectedTask?.principalAsset}
          assignedUser={selectedTask?.assignedUser}
          priority={selectedTask?.priority}
          initiator={selectedTask?.initiator}
          openedAt={selectedTask?.openedAt}
          value={selectedTask?.uid}
          isLoading={isLoading}
          permissions={permissions}
          slaFilter={slaFilter}
          t={t}
          dynamicBreadcrumbsSize={dynamicBreadcrumbsSize}
        />
      </Card>
      {onRemoveSelectedTask && (
        <Dialog
          title={`${t('ns.inbound_call_triage:remove_task')}`}
          isOpen={showRemoveTaskConfirmation}
          onClose={() => setShowRemoveTaskConfirmation(false)}
          isSmallDevice={false}
          actions={
            <>
              <Button variant="contained" onClick={onRemoveSelectedTask}>
                {`${t('ns.inbound_call_triage:remove_task')}`}
              </Button>
              <Button variant="outlined" onClick={() => setShowRemoveTaskConfirmation(false)}>
                {t('common:cancel')}
              </Button>
            </>
          }
        >
          <Alert severity="error">{`${t('ns.inbound_call_triage:remove_task:confirm_text')}`}</Alert>
        </Dialog>
      )}
    </>
  );
};
